import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const EventScheduleSlice=createSlice({
    name:"EventSchedule",
    initialState,
    reducers:{
        setEventType(state, action: PayloadAction<string>){
             state.type=action.payload
             /* 
            return ;  */
        },
        getEventType(state,action){
      
        }

    }


});

export default EventScheduleSlice.reducer

export const {setEventType,getEventType}=EventScheduleSlice.actions;

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  tabName: string
  id: number
  name: string
  action: string
  umapFriend: string
  umapFriendId: number
  openDetailOption: string
  addFriendFromScreen: string
  currentTab: string
  isEditAllow:boolean
}
const initialState: IssueInitialState = {
  tabName: '',
  id: 0,
  name: '',
  action: '',
  umapFriend: '',
  umapFriendId: 0,
  openDetailOption: '',
  addFriendFromScreen: '',
  currentTab: '',
  isEditAllow:true
}
const selectFriendSlice = createSlice({
  name: 'selectFriend',
  initialState,
  reducers: {
    setData(
      state,
      action: PayloadAction<{tabName: string; id: number; name: string; action: string}>
    ) {
      state.tabName = action.payload.tabName
      state.id = action.payload.id
      state.name = action.payload.name
      state.action = action.payload.action
    },
    setUmapFriend(state, action: PayloadAction<{umapFriend: string; umapFriendId: number}>) {
      state.umapFriend = action.payload.umapFriend
      state.umapFriendId = action.payload.umapFriendId
    },
    setOpenDetailOption(state, action: PayloadAction<string>) {
      state.openDetailOption = action.payload
    },
    setaddFriendFromScreen(state, action: PayloadAction<string>) {
      state.addFriendFromScreen = action.payload
    },
    setCurrentTab(state,action:PayloadAction<string>){
        state.currentTab=action.payload

    },setShowMessageForEditFriend(state,action:PayloadAction<boolean>){
       state.isEditAllow=action.payload
    }
  },
})

export default selectFriendSlice.reducer

export const {setData, setUmapFriend, setOpenDetailOption, setaddFriendFromScreen,setCurrentTab,setShowMessageForEditFriend} =
  selectFriendSlice.actions

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  showModal: boolean
  deleteFriendModal: boolean
  drawer: boolean
  deleteGroupModal: boolean
  showGroupMessages: boolean
  showGroupMessageModal: boolean
  showSettingDeleteModal: boolean
  showMessage:boolean
  showDeleteMessageModalForFriends:boolean
  showGroupCreationMessage:boolean,
  showUserManagementModal:boolean,
  showGDEFMessage:boolean,
  hideDropDown:boolean
}
const initialState: IssueInitialState = {
  showModal: false,
  deleteFriendModal: false,
  drawer: false,
  deleteGroupModal: false,
  showGroupMessages: false,
  showGroupMessageModal: false,

  showSettingDeleteModal: false,
  showMessage:false,
  showDeleteMessageModalForFriends:false,
  showGroupCreationMessage:false,
  showUserManagementModal:false,
  showGDEFMessage:false,
  hideDropDown:false
}
const ShowModalSlice = createSlice({
  name: 'ShowModal',
  initialState,
  reducers: {
    setReduxShowModal(state, action: PayloadAction<{showModal: boolean}>) {
      state.showModal = action.payload.showModal
    },
    setDeleteFriendModal(state, action: PayloadAction<boolean>) {
      state.deleteFriendModal = action.payload
    },
    setDrawer(state, action: PayloadAction<boolean>) {

      state.drawer = action.payload
    },
    setDeleteGroupModal(state, action: PayloadAction<boolean>) {
      state.deleteGroupModal = action.payload
    },
    setGroupMessageComp(state, action: PayloadAction<boolean>) {
      state.showGroupMessages = action.payload
    },
    setGroupMessageModal(state, action: PayloadAction<boolean>) {
      state.showGroupMessageModal = action.payload
    },
    setShowDeleteModalSetting(state, action: PayloadAction<boolean>) {
      state.showSettingDeleteModal = action.payload
    },
    setShowMessageModalForUshare(state, action: PayloadAction<boolean>) {
      state.showMessage = action.payload
    },
    setShowDeleteMessageModalForFriends(state, action: PayloadAction<boolean>) {
      state.showDeleteMessageModalForFriends = action.payload
    },
    setShowGroupCreationMessage(state, action: PayloadAction<boolean>) {
      state.showGroupCreationMessage = action.payload
    },
    setShowUserManagementModal(state, action: PayloadAction<boolean>) {
      state.showUserManagementModal = action.payload
    },
    setShowGDEFMessage(state, action: PayloadAction<boolean>) {
      state.showGDEFMessage = action.payload
    },
    setHideDropDown(state, action: PayloadAction<boolean>) {
      state.hideDropDown = action.payload
    },
  },
})

export default ShowModalSlice.reducer

export const {
  setReduxShowModal,
  setDeleteFriendModal,
  setDrawer,
  setDeleteGroupModal,
  setGroupMessageComp,
  setGroupMessageModal,
  setShowDeleteModalSetting,
  setShowMessageModalForUshare,
  setShowDeleteMessageModalForFriends,
  setShowGroupCreationMessage,
  setShowUserManagementModal,
  setShowGDEFMessage,
  setHideDropDown
} = ShowModalSlice.actions

import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const ShareScheduleMediaSlice=createSlice({
    name:"ShareScheduleMedia",
    initialState,
    reducers:{
        setShareMedia(state, action: PayloadAction<string>){
             state.type=action.payload
       
         
        },
        getShareMedia(state,action){
      
        }
        
    }


});

export default ShareScheduleMediaSlice.reducer

export const {setShareMedia,getShareMedia}=ShareScheduleMediaSlice.actions;
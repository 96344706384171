import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  type: string
  personalCalnderViewCallApi: number
  SharedCalnderViewCallApi: number
  personalScheduleViewCallApi: number
  SharedScheduleViewCallApi: number
  module: string
  eventCreatedBy: number
  comeFrom: string
  isAddToCalender:boolean

  eventName:string,
  location:string,
  startTime:string,
  startDate:string,
  endDate:string,
  endTime:string

}
const initialState: IssueInitialState = {
  type: '',
  personalCalnderViewCallApi: 0,
  SharedCalnderViewCallApi: 0,
  personalScheduleViewCallApi: 0,
  SharedScheduleViewCallApi: 0,
  module: '',
  eventCreatedBy: 0,
  comeFrom: '',
  isAddToCalender:false,
  eventName:"",
  location:"",
  startTime:"",
  startDate:"",
  endDate:"",
  endTime:""
}
const HomePageCarDropDownSlice = createSlice({
  name: 'HomePageCarDropDown',
  initialState,
  reducers: {
    setCardItem(state, action: PayloadAction<string>) {
      state.type = action.payload
 
      /* 
            return ;  */
    },
    getCardItem(state, action) {

    },
    setCallApi(state, action: PayloadAction<{screen: string; random_number: number}>) {
      if (action.payload.screen === 'share_schedule') {
        state.SharedScheduleViewCallApi = action.payload.random_number
      } else if (action.payload.screen === 'personal_schedule') {
        state.personalScheduleViewCallApi = action.payload.random_number
      }
    },
    setModule(state, action: PayloadAction<string>) {
      state.module = action.payload
    },
    setEventCreatedById(state, action: PayloadAction<number>) {
      state.eventCreatedBy = action.payload
    },
    setComeFrom(state, action: PayloadAction<string>) {
      state.comeFrom = action.payload
    },
    setIsAddCalender(state,action:PayloadAction<boolean>){
      state.isAddToCalender = action.payload
    },
    setCalenderData(state,action:PayloadAction<{eventName:string,location:string,startDate:string,startTime:string,endDate:string,endTime:string}>){

      state.eventName=action.payload.eventName
      state.location=action.payload.location
      state.startDate=action.payload.startDate
      state.startTime=action.payload.startTime
      state.endDate=action.payload.endDate
      state.endTime=action.payload.endTime
    }
  },
})


export default HomePageCarDropDownSlice.reducer

export const {setCardItem, getCardItem, setCallApi, setModule, setEventCreatedById, setComeFrom,setIsAddCalender,setCalenderData} =
  HomePageCarDropDownSlice.actions

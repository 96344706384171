import { FC, useEffect, Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAuth } from '../modules/auth';
import { MenuComponent } from '../../_metronic/assets/ts/components';
import { LayoutSplashScreen } from '../../_metronic/layout/core';

// Lazy loaded components
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const ErrorsPage = lazy(() => import('../modules/errors/ErrorsPage'));
const Logout = lazy(() => import('../modules/auth').then(module => ({ default: module.Logout })));
const AuthPage = lazy(() => import('../modules/auth').then(module => ({ default: module.AuthPage })));
const SignInPage = lazy(() => import('../pages/Auth/SignInPage'));
const SignUpPage = lazy(() => import('../pages/Auth/SignUpPage'));
const App = lazy(() => import('../App'));
const ForgotPasswordPage = lazy(() => import('../pages/Auth/ForgetPasswordPage'));
const EmailVarificationPage = lazy(() => import('../pages/Auth/EmailVarificationPage'));
const NewPasswordPage = lazy(() => import('../pages/Auth/NewPasswordPage'));

const HomePage = lazy(() => import('../pages/Home/HomePage'));
const EventPage = lazy(() => import('../pages/Events/EventPage'));
const Freinds = lazy(() => import('../modules/friends/Friends'));
const FriendEvents = lazy(() => import('../modules/friends/FriendEvents'));
const SelectLocationModal = lazy(() => import('../../_metronic/partials').then(module => ({ default: module.SelectLocationModal })));
const MenuBar = lazy(() => import('../modules/Umap/components/MenuBar'));
const NewPrivateRoute = lazy(() => import('./NewPrivateRoute'));
const AcceptInvitation = lazy(() => import('../modules/auth/components/AcceptInvitation'));
const Home_Page = lazy(() => import('../pages/Lending_page/MainPages/HomePage'));
const Aboutpage = lazy(() => import('../pages/Lending_page/MainPages/Aboutpage'));
const LearnMore = lazy(() => import('../pages/Lending_page/MainPages/LearnMore'));
const LearnHowItWorks = lazy(() => import('../pages/Lending_page/MainPages/LearnHowItWorks'));
const WalkthroughLayout = lazy(() => import('../modules/walkthrough copy/WalkthroughLayout'));
const PrivacyPolicy = lazy(() => import('../pages/Lending_page/MainPages/PrivacyPolicy'));
const Disclaimer = lazy(() => import('../pages/Lending_page/MainPages/Disclaimer'));
const TermOfUse = lazy(() => import('../pages/Lending_page/MainPages/TermOfUse'));

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  let user_id = localStorage.getItem('user_id');

  const friend = useSelector((state: RootState) => state.SelectFriend.umapFriend);
  const groupName = useSelector((state: RootState) => state.RightToolBar.groupName);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 50);
  }, []);

  return (
    <Suspense fallback={<div className='loader-container'>
    <div className='spinner'></div>
  </div>}>
    <BrowserRouter>
    
        <Routes>
          <Route path='' element={<Home_Page />} />
          <Route path='about' element={<Aboutpage />} />
          <Route path='learnmore' element={<LearnMore />} />
          <Route path='howitworks' element={<LearnHowItWorks />} />
          <Route path='privacy' element={<PrivacyPolicy />} />
          <Route path='disclaimer' element={<Disclaimer />} />
          <Route path='termofuse' element={<TermOfUse />} />
          <Route element={<App />}>
            <Route path='signin' element={<SignInPage />} />
            <Route path='Signup' element={<SignUpPage />} />
            <Route path='forgetpassword' element={<ForgotPasswordPage />} />
            <Route path='emailverification' element={<EmailVarificationPage />} />
            <Route path='newpassword' element={<NewPasswordPage />} />
            <Route path='/userInvitation/:id/:status' element={<AcceptInvitation />} />
            <Route element={<NewPrivateRoute />}>
              <Route path='schedule/create_event' element={<EventPage />} />
              <Route path='schedule/edit_event' element={<EventPage />} />
              <Route path='schedule' element={<HomePage />} />
              <Route path='schedule/event_history' element={<EventPage />} />
              <Route path='schedule/manage_shared_schedule' element={<EventPage />} />
              <Route path='schedule/personal_schedule' element={<EventPage />} />
              <Route path='ushare/friends' element={<EventPage />} />
              <Route path={`ushare/friends/${friend?.split(' ').join('_')}`} element={<EventPage />} />
              <Route path={`ushare/friends/${groupName.split(' ').join('_')}`} element={<EventPage />} />
              <Route path='ushare/share_your_schedule' element={<EventPage />} />
              <Route path='ushare/share_your_schedule/:codeString' element={<EventPage />} />
              <Route path='ushare/create_a_custom_umaps_qr_code' element={<EventPage />} />
              <Route path='ushare/create_qr_code' element={<EventPage />} />
              <Route path='Umap' element={<EventPage />} />
              <Route path='Umap/Directions' element={<EventPage />} />
              <Route path='Umap/located_events' element={<EventPage />} />
              <Route path='search' element={<EventPage />} />
              <Route path='select_schedule' element={<EventPage />} />
              <Route path='analytics' element={<EventPage />} />
              <Route path='upad' element={<EventPage />} />
              <Route path='feedback' element={<EventPage />} />
              <Route path='FAQs' element={<EventPage />} />
              <Route path='setting/account' element={<EventPage />} />
              <Route path='user_administration/manage_user' element={<EventPage />} />
              <Route path='subscriptions_plan' element={<EventPage />} />
              <Route path='ai_chat' element={<EventPage />} />
              <Route path='group_messages' element={<EventPage />} />
              <Route path='walkthrough' element={<WalkthroughLayout />} />
              <Route path='ushare/event_detail/:eventId' element={<EventPage />} />
              <Route path='event_inviatation/event_detail' element={<EventPage />} />
            </Route>
          </Route>
        </Routes>
   
    </BrowserRouter>
    </Suspense>
  );
};

export { AppRoutes };

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  type: string
  friendId: number
  id:number
  friendType: string
  friendName: string
  deleteFriendTab: string
  deleteFriendId: number
  deleteFriendCategory: string
  friendTab:string,
  AddFriendToEventResponse:boolean,
  tabName:string,

}
const initialState: IssueInitialState = {
  type: '',
  id:0,
  friendId: 0,
  friendType: '',
  friendName: '',
  deleteFriendTab: '',
  deleteFriendId: 0,
  deleteFriendCategory: '',
  friendTab:"",
  AddFriendToEventResponse:false,
  tabName:''

}
const FriendDropDownSlice = createSlice({
  name: 'FriendDropDown',
  initialState,
  reducers: {
    setItem(state, action: PayloadAction<string>) {
      state.type = action.payload

      /* 
            return ;  */
    },
    setFriendIdAndType(
      state,
      action: PayloadAction<{id:number,friendId: number; friendType: string; friendName: string}>
    ) {

      state.friendId = action.payload.friendId
      state.friendType = action.payload.friendType
      state.friendName = action.payload.friendName
      state.id=action.payload.id
    },
    setDeleteFriend(
      state,
      action: PayloadAction<{
        id:number
        deleteFriendId: number
        deleteFriendCategory: string
        deleteFriendTab: string
      }>
    ) {
      state.deleteFriendId = action.payload.deleteFriendId
      state.deleteFriendCategory = action.payload.deleteFriendCategory
      state.deleteFriendTab = action.payload.deleteFriendTab
      state.id=action.payload.id
    },
    setFriendId(
        state,
        action: PayloadAction<{id:number,friendId: number; friendType: string; friendName: string,friendTab:string}>
      ) {
    
        state.friendId = action.payload.friendId
        state.friendType = action.payload.friendType
        state.friendName = action.payload.friendName
        state.friendTab = action.payload.friendTab
        state.id=action.payload.id
      },
    ShowAddToEventResponse(state,action:PayloadAction<boolean>){
        state.AddFriendToEventResponse=action.payload

    },
    setTabName(state,action:PayloadAction<string>){
      state.tabName=action.payload
  },
  },
})


export default FriendDropDownSlice.reducer

export const {setItem, setFriendIdAndType, setDeleteFriend,setFriendId,
    ShowAddToEventResponse,setTabName} = FriendDropDownSlice.actions

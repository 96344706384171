import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const SearchCategoryDropDownSlice=createSlice({
    name:"SearchCategoryDropDown",
    initialState,
    reducers:{
        setSearchCategoryItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getSearchCategoryItem(state,action){
    
        },setEmptyCategory(state){
            state.type=""

        }

        
    }


});


export default SearchCategoryDropDownSlice.reducer

export const {setSearchCategoryItem,getSearchCategoryItem,setEmptyCategory}=SearchCategoryDropDownSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string,
    deleteGroupId:number
}
const initialState: IssueInitialState = {
    type:"",
    deleteGroupId:0
}
const GroupDropDownSlice=createSlice({
    name:"GroupDropDown",
    initialState,
    reducers:{
        setItem(state, action: PayloadAction<string>){
             state.type=action.payload
         
             /* 
            return ;  */
        },
        setDeleteGroupId(state, action: PayloadAction<number>){
            state.deleteGroupId=action.payload
            
       },
        
    }


});


export default GroupDropDownSlice.reducer

export const {setItem,setDeleteGroupId}=GroupDropDownSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    myEventApiForCustomQr: number
}
const initialState: IssueInitialState = {
    myEventApiForCustomQr:0
}
const ApiCallIntailtor=createSlice({
    name:"ApiCallIntailtor",
    initialState,
    reducers:{
        setMyEventApiForCustomQr(state, action: PayloadAction<number>){
             state.myEventApiForCustomQr=action.payload
        },
        
    }


});


export default ApiCallIntailtor.reducer

export const {setMyEventApiForCustomQr}=ApiCallIntailtor.actions;
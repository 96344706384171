import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  searchQuery: string
}
const initialState: IssueInitialState = {
  searchQuery: '',
}
const SearchEventSlice = createSlice({
  name: 'SearchEvent',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
  },
})


export default SearchEventSlice.reducer

export const {setSearch} = SearchEventSlice.actions

import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    id:string,
    name: string
}
const initialState: IssueInitialState = {
    id:"",
    name: ""
}
const InviteUserSlice=createSlice({
    name:"InviteUser",
    initialState,
    reducers:{
        setDetail(state, action: PayloadAction<{id:string,name:string}>){
            
            state.id=action.payload.id;
            state.name=action.payload.name;
             
        },
      
        
    }


});


export default InviteUserSlice.reducer

export const {setDetail}=InviteUserSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string,
    addToSharedType:string
}
const initialState: IssueInitialState = {
    type:"",
    addToSharedType:""
}
const AddToScheduleSlice=createSlice({
    name:"AddToSchedule",
    initialState,
    reducers:{
        setSchedule(state, action: PayloadAction<string>){
             state.type=action.payload
        
             /* 
            return ;  */
        },
        getSchedule(state,action){
        
        },
        setAddToSharedType(state, action: PayloadAction<string>){
            state.addToSharedType=action.payload
         
       },
        
    }


});


export default AddToScheduleSlice.reducer

export const {setSchedule,getSchedule,setAddToSharedType}=AddToScheduleSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string,
    password:string

}
const initialState: IssueInitialState = {
    type:"",
    password:""
}
const SelectSchoolDropDown=createSlice({
    name:"SelectSchoolDropDown2",
    initialState,
    reducers:{
        setSchool(state, action: PayloadAction<string>){
             state.type=action.payload
           
        },
        setPassword(state,action:PayloadAction<string>){
            state.password=action.payload

        }
    
        
    }


});


export default SelectSchoolDropDown.reducer

export const {setSchool,setPassword}=SelectSchoolDropDown.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const CardEventDropDown=createSlice({
    name:"CardEvent",
    initialState,
    reducers:{
        setEventDropDownItem(state, action: PayloadAction<string>){
             state.type=action.payload
    
             /* 
            return ;  */
        },
        getEventDropDownItem(state,action){
        
        }
        
    }


});


export default CardEventDropDown.reducer

export const {setEventDropDownItem,getEventDropDownItem}=CardEventDropDown.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: boolean
}
const initialState: IssueInitialState = {
    type:false
}
const CreateNewPinPointSlice=createSlice({
    name:"CreateNewPinPoint",
    initialState,
    reducers:{
        setCreateNewPinPoint(state, action: PayloadAction<boolean>){
             state.type=action.payload
        
             /* 
            return ;  */
        },
    }


});


export default CreateNewPinPointSlice.reducer

export const {setCreateNewPinPoint}=CreateNewPinPointSlice.actions;
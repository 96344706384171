import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import axios from 'axios'
import { isError } from 'react-query'
const signUpSchema = Yup.object({
  newPassword: Yup.string()
    .min(7)
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%*#?&]{7,}$/,
      'Password must contain at least one uppercase letter, one number, and one special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

type UpdatePasswordProps = {
  show: boolean
  onHide: () => void
  email: string
}
const UpdatePassword = ({show, onHide, email}: UpdatePasswordProps) => {
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false)
  const [toggleConfirmPasswordIcon, setToggleConfirmPasswordIcon] = useState(false)
  const [status,setStatus]=useState({
     isError:false,
     message:"",
     isSuccess:false
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      updateOldUserPassword()
    },
  })

  useEffect(() => {
    formik.resetForm()
  }, [show])

  function togglePassword() {
    setTogglePasswordIcon(!togglePasswordIcon)
    var x = document.getElementById('newPassword') as HTMLInputElement
    console.log(x.type)
    if (x.type === 'password') {
      x.type = 'text'
    } else {
      x.type = 'password'
    }
  }

  function toggleConfirmPassword() {
    setToggleConfirmPasswordIcon(!toggleConfirmPasswordIcon)
    var x = document.getElementById('confirmPassword') as HTMLInputElement
    if (x.type === 'password') {
      x.type = 'text'
    } else {
      x.type = 'password'
    }
  }

  const updateOldUserPassword = async () => {
    const formData = new FormData()
    formData.append('password', formik.values.newPassword)
    formData.append('email', email)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/setOldUserPassword`,
        formData
      )
      setStatus({
        isError:false,
        message:response.data.message,
        isSuccess:true
    })
      setTimeout(() => {
        onHide();
      }, 2000); 
    
    } catch (error:any) {
        setStatus({
            isError:true,
            message:error.response.data.message,
            isSuccess:false
        })

    }
  }
  const inputFIle = {
    fontSize: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
  }

  let styleForFormIcon = {
    maindiv: {
      position: 'relative',
      display: 'flex',
      /* justifyContent: "end",
      alignItems: "end", */
    },
    icon: {
      position: 'absolute',
      top: '12px',
      /* left: "97%", */

      color: '#4b00ff',
      height: '24px',
      width: '24px',
      display: 'flex',
      /* marginRight: "10px", */
      marginRight: '50px',
    },
  }
  const styleForInput = {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    paddingLeft: '50px',
    color: 'rgba(189, 189, 189, 1)',
    /* width: "458px", */
    height: '48px',
    borderRadius: '8px',
    /* width:"600px" */
  }
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body style={{background: 'black', padding: '30px'}}>
        <div className='d-flex justify-content-center' style={{marginBottom: '48px'}}>
          <h2
            className='text-white'
            /* style={{marginLeft:"45px"}} */ style={{fontSize: '24px', fontWeight: '600'}}
          >
            Welcome Back
          </h2>
        </div>

        {status.isError ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold' style={{color: 'red'}}>
      
            
               {status.message}
          </div>
        </div>
      ) : (
        ''
      )}
            {status.isSuccess ? (
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold' style={{color: 'green'}}>
      
            
               {status.message}
          </div>
        </div>
      ) : (
        ''
      )}

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          /*    onSubmit={handleSubmit} */
          onSubmit={formik.handleSubmit}
        >
          <div className='input-wrapper'>
            {/* begin::Form group Password */}
            <div className='fv-row ' data-kt-password-meter='true' style={{marginBottom: '16px'}}>
              <div
                className='col-12 form-input'
                style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
              >
                <input
                  type='password'
                  className='form-control'
                  style={{...styleForInput}}
                  placeholder='Password*'
                  id='newPassword'
                  name='newPassword'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />

                <img
                  src={toAbsoluteUrl('/media/Home/lock.png')}
                  alt=''
                  style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
                />
                {togglePasswordIcon ? (
                  <img
                    className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/openEye.svg')}
                    alt=''
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      height: '24px',
                      width: '24px',
                    }}
                    onClick={togglePassword}
                  />
                ) : (
                  <img
                    className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/closeEye.svg')}
                    alt=''
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      height: '24px',
                      width: '24px',
                    }}
                    onClick={togglePassword}
                  />
                )}
              </div>
              {formik.errors.newPassword && formik.touched.newPassword ? (
                <div className='alert-text font-weight-bold text-danger'>
                  {formik.errors.newPassword}
                </div>
              ) : null}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row ' style={{marginBottom: '32px'}}>
              <div
                className='col-12 form-input'
                style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
              >
                <input
                  type='password'
                  className='form-control'
                  style={{...styleForInput}}
                  placeholder='Confirm Password*'
                  id='confirmPassword'
                  name='confirmPassword'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />

                <img
                  src={toAbsoluteUrl('/media/Home/lock.png')}
                  alt=''
                  style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
                />
                {toggleConfirmPasswordIcon ? (
                  <img
                    className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/openEye.svg')}
                    alt=''
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      height: '24px',
                      width: '24px',
                    }}
                    onClick={toggleConfirmPassword}
                  />
                ) : (
                  <img
                    className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/closeEye.svg')}
                    alt=''
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      height: '24px',
                      width: '24px',
                    }}
                    onClick={toggleConfirmPassword}
                  />
                )}
              </div>

              {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                <div className='alert-text font-weight-bold text-danger'>
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </div>

          <div className='row my-12'>
            <div className='col-6'>
              <button className='btn text-white col-12 mb-0' style={{background: 'red'}}>
                Done
              </button>
            </div>
            <div className='col-6'>
              <button
                className='btn  col-12 mb-0'
                style={{
                  background: 'transparent',
                  border: '1px solid rgba(130, 130, 130, 1)',
                  color: 'rgba(130, 130, 130, 1)                  ',
                }}
                onClick={() => {
                  onHide()
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default UpdatePassword

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  QrImg:string,
  SelectedEvents: Array<number>
  isAllFirendAllCheck: boolean
  AllFriendList: Array<object>

  isFavoriteAllCheck: boolean
  FavoriteFriendList: Array<object>

  isGroupAllCheck: boolean
  GroupList: Array<object>

  isPersonalAllCheck: boolean
  PersonalList: Array<object>

  isAllNonUmapFriendCheck: boolean
  NonUmapFriendList: Array<object>

  isNonUmapFavoritCheck: boolean
  NonUmapFavoritFriendList: Array<object>
  apiStatus:boolean,
  apiMessage:string,
  sendToInAppFriend:boolean,
  showSelectFriendModal:boolean
  asideMenuItemClick:boolean
  callListingApi:number
  showShareButton:boolean
  isShowAddToScheuldePopUp:boolean
  eventId:number,
  isLeaveEvent:boolean
  showResponseAferLeave:boolean

  isLocationExist:boolean
  isShareViaTextAllowed:boolean
  isCloseShareDrawer:boolean
  googleCalendar:Array<string>
  outLookCalendar:Array<string>
  scanFromLink:boolean
  comeFromDeeplink:boolean
  showPopForDeepLink:boolean

  PopUpBlockerMessage:boolean
  
}
const initialState: IssueInitialState = {
  QrImg:"",
  SelectedEvents: [],
  isAllFirendAllCheck: false,
  AllFriendList: [],

  isFavoriteAllCheck: false,
  FavoriteFriendList: [],

  isGroupAllCheck: false,
  GroupList: [],

  isPersonalAllCheck: false,
  PersonalList: [],

  isAllNonUmapFriendCheck: false,
  NonUmapFriendList: [],

  isNonUmapFavoritCheck: false,
  NonUmapFavoritFriendList: [],
  apiStatus:false,
  apiMessage:"",
  sendToInAppFriend:false,


  showSelectFriendModal:false,

  asideMenuItemClick:false,
  callListingApi:0,
  showShareButton:false,
  isShowAddToScheuldePopUp:false,
  eventId:0,
  isLeaveEvent:false,
  showResponseAferLeave:false,
  isLocationExist:false,
  isShareViaTextAllowed:false,
  isCloseShareDrawer:false,
  googleCalendar:[],
  outLookCalendar:[],
  scanFromLink:false,
  comeFromDeeplink:false,
  showPopForDeepLink:false,
  PopUpBlockerMessage:false
}
const ShareYourScheduleDataslice = createSlice({
  name: 'ShareYourScheduleDataslice',
  initialState,
  reducers: {
    addEventId(state, action: PayloadAction<number>) {
      state.SelectedEvents.push(action.payload)
    },
    removeEventId(state, action: PayloadAction<number>) {
      state.SelectedEvents = state.SelectedEvents.filter((id) => id !== action.payload)
    },
    setIsCheckAllFriend(state, action: PayloadAction<boolean>) {
      state.isAllFirendAllCheck = action.payload
    },
    setAllFriendsId(state, action: PayloadAction<object>) {
      state.AllFriendList.push(action.payload)
    },
    removeIdFromAllFriendList(state, action: PayloadAction<number>) {
      let data = state.AllFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.AllFriendList = data
    },

    setFavroitFriendsId(state, action: PayloadAction<object>) {
      state.FavoriteFriendList.push(action.payload)
    },
    removeIdFromFavroitFriendsId(state, action: PayloadAction<number>) {
      let data = state.FavoriteFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.FavoriteFriendList = data
    },
    setGroupId(state, action: PayloadAction<object>) {
      state.GroupList.push(action.payload)
    },
    removeIdFromGroupList(state, action: PayloadAction<number>) {
      let data = state.GroupList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.GroupList = data
    },
    setPersonalFriendList(state, action: PayloadAction<object>) {
      state.PersonalList.push(action.payload)
    },
    removeIdFromPersonalFriendList(state, action: PayloadAction<number>) {
      let data = state.PersonalList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.PersonalList = data
    },
    setAllNonUmapFriendList(state, action: PayloadAction<object>) {
      state.NonUmapFriendList.push(action.payload)
    },
    removeIdFromAllNonUmapFriendList(state, action: PayloadAction<number>) {
      let data = state.NonUmapFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.NonUmapFriendList = data
    },

    setNonUmapFavroitFriendList(state, action: PayloadAction<object>) {
      state.NonUmapFavoritFriendList.push(action.payload)
    },
    removeIdFromNonUmapFavroitFriendList(state, action: PayloadAction<number>) {
      let data = state.NonUmapFavoritFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.NonUmapFavoritFriendList = data
    },
    setApiResponse(state, action: PayloadAction<{apiStatus: boolean; apiMessage: string}>) {
      state.apiStatus = action.payload.apiStatus
      state.apiMessage = action.payload.apiMessage
    },
    setSendToInAppFriend(state, action: PayloadAction<boolean>) {
      state.sendToInAppFriend = action.payload

    },
    setSelectModal(state, action: PayloadAction<boolean>){
      state.showSelectFriendModal=action.payload
    },
    setQrImg(state, action: PayloadAction< string>){
      state.QrImg=action.payload;

    },
    setEmptyState(state){
      state.AllFriendList=[];
      state.FavoriteFriendList=[];
      state.GroupList=[];
      state.SelectedEvents=[];
      state.QrImg="";
      state.NonUmapFavoritFriendList=[];
      state.NonUmapFriendList=[];
      state.PersonalList=[];

    },
    setAllFriendsListEmpty(state){
      state.AllFriendList=[];
      state.FavoriteFriendList=[];
      state.GroupList=[];
      state.NonUmapFavoritFriendList=[];
      state.NonUmapFriendList=[];
      state.PersonalList=[];
    },
    setEmptyAllFriendList(state){
      state.AllFriendList=[]

    },
    setEmptyNonUmapFriendList(state){
      state.NonUmapFriendList=[]

    },
    setEmptyEventState(state){
      state.SelectedEvents=[];
    },
    setAsideMenuItemClick(state,action:PayloadAction<boolean>){
      state.asideMenuItemClick = action.payload;
    },
    setCallListingApi(state,action:PayloadAction<number>)
    {
      state.callListingApi=action.payload
    },
    setShowShareButton(state,action:PayloadAction<boolean>){
      state.showShareButton = action.payload;
    },
    setShowAddToSchedulePopUp(state,action:PayloadAction<boolean>){
      state.isShowAddToScheuldePopUp = action.payload;
    },
    setEventIdForAddToSchedule(state,action:PayloadAction<number>){
      state.eventId = action.payload;
    },
    setIsLeaveEvent(state,action:PayloadAction<boolean>){
      state.isLeaveEvent = action.payload;
    }, setShowResponseAfterLeave(state,action:PayloadAction<boolean>){
      state.showResponseAferLeave = action.payload;
    },
    setIsLocationExist(state,action:PayloadAction<boolean>){
      state.isLocationExist = action.payload;
    },
    setIshareViaTextAllowed(state,action:PayloadAction<boolean>){
      state.isShareViaTextAllowed = action.payload;
    },
    setIscloseShareDrawer(state,action:PayloadAction<boolean>){
      state.isCloseShareDrawer = action.payload;
    },
    setGoogleCalendarLink(state,action:PayloadAction<any>){
      state.googleCalendar.push(action.payload)   
    },
    setOutLookCalendarLink(state,action:PayloadAction<any>){
      state.outLookCalendar.push(action.payload)
    },
    setEmptyCalendarState(state){
      state.googleCalendar = []
      state.outLookCalendar =[]

    },
    setScanFromLink(state, action: PayloadAction<boolean>){
      state.scanFromLink=action.payload
    },
    setComeFromDeepLink(state, action: PayloadAction<boolean>){
      state.comeFromDeeplink=action.payload
    },
    setShowPopUpForDeepLink(state, action: PayloadAction<boolean>){
      state.showPopForDeepLink=action.payload
    },
    setPopUpBlockerMessage(state, action: PayloadAction<boolean>){
      state.PopUpBlockerMessage=action.payload
    },
  },
})

export default ShareYourScheduleDataslice.reducer

export const {
  addEventId,
  removeEventId,
  setAllFriendsId,
  removeIdFromAllFriendList,
  setFavroitFriendsId,
  removeIdFromFavroitFriendsId,
  setGroupId,
  removeIdFromGroupList,
  setPersonalFriendList,
  removeIdFromPersonalFriendList,
  setAllNonUmapFriendList,
  removeIdFromAllNonUmapFriendList,
  setNonUmapFavroitFriendList,
  removeIdFromNonUmapFavroitFriendList,
  setApiResponse,
  setSendToInAppFriend,
  setSelectModal,
  setQrImg,
  setEmptyState,

  setAsideMenuItemClick,
  setCallListingApi,
  setShowShareButton,
  setEmptyEventState,
  setShowAddToSchedulePopUp,
  setEventIdForAddToSchedule,
  setIsLeaveEvent,
  setShowResponseAfterLeave,
  setIsLocationExist,
  setIshareViaTextAllowed,
  setIscloseShareDrawer,
  setGoogleCalendarLink,
  setOutLookCalendarLink,
  setEmptyCalendarState,
  setScanFromLink,
  setComeFromDeepLink,
  setShowPopUpForDeepLink,
  setPopUpBlockerMessage,
  setEmptyAllFriendList,
  setEmptyNonUmapFriendList,
  setAllFriendsListEmpty
} = ShareYourScheduleDataslice.actions

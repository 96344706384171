import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMarker} from "./UMapSlice";

interface ITripInfo {
    origin?: IMarker | undefined,
    destinations: IMarker[],
    distance: string,
    duration: string,
}

export interface ITripListing {
    id: number
    user_id: number
    name: string
    details: string
    start_date: string
    end_date: string
    stop_count: string
    trip_distance: string
    trip_duration: string
    transport_mode: string
    created_at: string
    updated_at: string
    event_id: any
    u_trip_stops: any[]
}

interface IUTripSlice {
    origin?: IMarker | undefined,
    destinations: IMarker[],
    distance: string,
    duration: string,
    UTripListing: ITripListing[]
}

const initialState: IUTripSlice = {
    destinations: [],
    distance: '',
    duration: '',
    UTripListing: []
}

const UTripSlice = createSlice({
    name: 'UTrip',
    initialState,
    reducers: {
        setTripInfo: (state, action: PayloadAction<ITripInfo>) => {
            state.origin = action.payload.origin
            state.destinations = action.payload.destinations
            state.distance = action.payload.distance
            state.duration = action.payload.duration
        },
        setTripListing: (state, action: PayloadAction<ITripListing[]>) => {
            state.UTripListing = action.payload
        },
    }
})
export default UTripSlice.reducer

export const {
    setTripInfo,
    setTripListing
} = UTripSlice.actions

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  idsArray: Array<number>
  isShowAddNoteModal: boolean
  noteTitle: string
  noteId: number
  isNoteUpdated: boolean
  navigateFrom: string
  popUpForUmapSelectEvent: boolean
  selectedTabInUmap: string
  isAddToEvent:boolean
}
const initialState: IssueInitialState = {
  idsArray: [],
  isShowAddNoteModal: false,
  noteTitle: '',
  noteId: -1,
  isNoteUpdated: false,
  navigateFrom: '',
  popUpForUmapSelectEvent: false,
  selectedTabInUmap: '',
  isAddToEvent:false
}
const SelectEventIdSlice = createSlice({
  name: 'SelectEventIdSlice',
  initialState,
  reducers: {
    setEventId(state, action: PayloadAction<number>) {
      state.idsArray.push(action.payload)
    },
    removeId(state, action: PayloadAction<number>) {
      state.idsArray = state.idsArray.filter((id) => id !== action.payload)
    },

    setNoteModal(state, action: PayloadAction<boolean>) {
      state.isShowAddNoteModal = action.payload
    },

    setNoteInfo(state, action: PayloadAction<{noteTitle: string; noteId: number}>) {
      state.noteTitle = action.payload.noteTitle
      state.noteId = action.payload.noteId
    },

    setNoteUpdateState(state, action: PayloadAction<boolean>) {
      state.isNoteUpdated = action.payload
    },
    emptyAllState(state) {
      state.idsArray = []
    },
    setNavigateFrom(state, action: PayloadAction<string>) {
      state.navigateFrom = action.payload
    },
    setpopUpForUmapSelectEvent(state, action: PayloadAction<boolean>) {
      state.popUpForUmapSelectEvent = action.payload
    },
    setSelectedTabInUmap(state, action: PayloadAction<string>) {
      state.selectedTabInUmap = action.payload
    },
    setIsAddToEvent(state, action: PayloadAction<boolean>) {
      state.isAddToEvent = action.payload
    }
  },
})

export default SelectEventIdSlice.reducer

export const {
  setEventId,
  removeId,
  setNoteModal,
  setNoteInfo,
  emptyAllState,
  setNoteUpdateState,
  setNavigateFrom,
  setpopUpForUmapSelectEvent,
  setSelectedTabInUmap,
  setIsAddToEvent
} = SelectEventIdSlice.actions

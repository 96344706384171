import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const CategoryDropDownSlice=createSlice({
    name:"FriendCategoryDropDown",
    initialState,
    reducers:{
        setCategoryItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getCategoryItem(state,action){
  
        }
        
    }


});


export default CategoryDropDownSlice.reducer

export const {setCategoryItem,getCategoryItem}=CategoryDropDownSlice.actions;
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  StartDate: string
  StartTime: string
  EndDate: string
  EndTime: string
  SelectedDays: Array<string>
  screen:string
}
const initialState: IssueInitialState = {
  StartDate: '',
  StartTime: '',
  EndDate: '',
  EndTime: '',
  SelectedDays: [],
  screen:''
}
const EveryWeakEventData = createSlice({
  name: 'EveryWeakEventData',
  initialState,
  reducers: {
    setStartDate(state, action: PayloadAction<string>) {
      state.StartDate = action.payload
    },
    setStartTime(state, action: PayloadAction<string>) {
      state.StartTime = action.payload
    },
    setEndtDate(state, action: PayloadAction<string>) {
      state.EndDate = action.payload
    },
    setEndTime(state, action: PayloadAction<string>) {
      state.EndTime = action.payload
    },
    setSelectedDays(state, action: PayloadAction<{name: string; isChecked: boolean}>) {
      //let dayName=action.payload.name.charAt(0).toUpperCase() + action.payload.name.slice(1)
      if (action.payload.isChecked && !state.SelectedDays.includes(action.payload.name)) {
        state.SelectedDays.push(action.payload.name)
      } else if(!action.payload.isChecked) {        
        const indexToRemove = state.SelectedDays.indexOf(action.payload.name)
        if (indexToRemove !== -1) {
          state.SelectedDays.splice(indexToRemove, 1)
        }
      }
    },
    setScreen(state,action:PayloadAction<string>){
      state.screen = action.payload
    },
    emptyAllDateAndCategory(state) {
      state.EndDate = ''
      state.EndTime = ''
      state.SelectedDays = []
      state.StartDate = ''
      state.StartTime = ''
    },
  },
})

export default EveryWeakEventData.reducer

export const {
  setStartDate,
  setStartTime,
  setEndtDate,
  setEndTime,
  setSelectedDays,
  emptyAllDateAndCategory,
  setScreen,
} = EveryWeakEventData.actions

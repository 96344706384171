import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  subscriptionPlan:Array<any>,
  subscriptionType:string,
  subscriptionPlanName:string,
  subscriptionPlanRemainingFreeDays:number,
  OperatingSystem:string,
  ushareSubscriptionId:number,
  showAsideDropdown:boolean,
  subAdminsLength:number,
  subName:string,
}
const initialState: IssueInitialState = {
  subscriptionPlan:[],
  subscriptionType:'',
  subscriptionPlanName:'',
  subscriptionPlanRemainingFreeDays:0,
  OperatingSystem:'',
  ushareSubscriptionId:0,
  showAsideDropdown:false,
  subAdminsLength:0,
  subName:""

}
const UserSubscriptionDetail = createSlice({
  name: 'UserSubscriptionDetail',
  initialState,
  reducers: {
   

    setSubscriptionDetail(state,action:PayloadAction<any>){

      state.subscriptionPlan=[];
        state.subscriptionPlan.push(action.payload)

    },
    setSubscripitionPlanType(state,action:PayloadAction<{subscriptionType:string,subscriptionPlanName:string}>){
      state.subscriptionType = action.payload.subscriptionType;
      state.subscriptionPlanName = action.payload.subscriptionPlanName;
    },
    setSubscriptionPlanRemainingFreeDays(state,action:PayloadAction<string>){
    /*   const MS_PER_DAY = 24 * 60 * 60 * 1000; // milliseconds per day
      const currentDate = new Date();
      const subscriptionDateObj = new Date(action.payload); */
      
      // Calculate the difference in days
     /*  const differenceInDays = Math.floor((currentDate.getTime() - subscriptionDateObj.getTime()) / MS_PER_DAY);
    
      // Check if the user is within the 7-day free access period
      const remainingDays = Math.max(3 - differenceInDays, 0); */
      //state.subscriptionPlanRemainingFreeDays = remainingDays
      state.subscriptionPlanRemainingFreeDays = Number(action.payload)
      // return remainingDays;
    },
    setOperatingSystem(state,action:PayloadAction<string>){
      state.OperatingSystem = action.payload;
    },
    setUshareSubscriptionId(state,action:PayloadAction<number>){
      state.ushareSubscriptionId = action.payload;
    },
    setShowAsideDropdown(state,action:PayloadAction<boolean>){
      state.showAsideDropdown = action.payload;
    },
    setSubAdminsLength(state,action:PayloadAction<number>){
      state.subAdminsLength = action.payload;
    },
    setSubName(state,action:PayloadAction<string>){
      state.subName = action.payload;
    },
  },
})

export default UserSubscriptionDetail.reducer

export const {setSubscriptionDetail,setSubscripitionPlanType,
  setSubscriptionPlanRemainingFreeDays,setOperatingSystem,setUshareSubscriptionId,setShowAsideDropdown,setSubAdminsLength,setSubName} =
UserSubscriptionDetail.actions

import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string,
    groupId:number,
    groupName:string,
    googleMapUrl:string
}
const initialState: IssueInitialState = {
    type:"",
    groupId:0,
    groupName:'',
    googleMapUrl: ''
}
const RightToolBarSlice=createSlice({
    name:"RightToolBar",
    initialState,
    reducers:{
        setRightBarItem(state, action: PayloadAction<string>){
             state.type=action.payload
     
             /* 
            return ;  */
        },
        setGroupId(state, action: PayloadAction<number>){

            state.groupId=action.payload

       },
        setGroupName(state,action:PayloadAction<string>){
            state.groupName = action.payload
        },
        setGoogleMapUrl(state,action:PayloadAction<string>){
            state.googleMapUrl = action.payload
        },
    }


});


export default RightToolBarSlice.reducer

export const {setRightBarItem,setGroupId,setGroupName, setGoogleMapUrl}=RightToolBarSlice.actions;

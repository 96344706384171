import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UsersList} from '../../../app/modules/profile/components/UsersList'

export interface IssueInitialState {
  IsShowAddUserModal: boolean
  ishowDeleteModal: boolean
  isShowEditModal: boolean
  callApiUserList: number
  userId:number
  userList: Array<object>
  editUserData:Array<any>
  userPermission:Array<string>

  selectedUser:Array<any>
  isShowInvited:boolean
  isShowMySharedSchedule:boolean

  selectedUserRole:Array<any>
  selectedUserPermission:Array<any>
  isCompnayInvitation:boolean
  isActionAllowed:boolean
  addUserLimit:number
  isAddOnActive:boolean
  afterAcceptPopUp:boolean
  afterAcceptInvitationMessage:string
  isShowLeaveScheduleModal:boolean
  isShowLeaveScheduleResponse:boolean
  selectedUserId:number
  companyUserInvitationCount:number
  selectUserForEvent:Array<any>
  showModalForExceedInviteLimit:boolean

}
const initialState: IssueInitialState = {
  IsShowAddUserModal: false,
  isShowEditModal: false,
  callApiUserList: 0,
  userId:0,
  userList: [],
  ishowDeleteModal: false,
  editUserData:[],
  userPermission:[],
  selectedUser:[],
  isShowInvited:false,
  isShowMySharedSchedule:false,
  selectedUserRole:[],
  selectedUserPermission:[],
  isCompnayInvitation:false,
  isActionAllowed:false,
  addUserLimit:0,
  isAddOnActive:false,
  afterAcceptPopUp:false,
  afterAcceptInvitationMessage:"",
  isShowLeaveScheduleModal:false,
  isShowLeaveScheduleResponse:false,
  selectedUserId:0,
  companyUserInvitationCount:0,
  selectUserForEvent:[],
  showModalForExceedInviteLimit:false


}
const UsermanagementSlice = createSlice({
  name: 'UsermanagementSlice',
  initialState,
  reducers: {
    setAddUserModal(state, action: PayloadAction<boolean>) {
      state.IsShowAddUserModal = action.payload
    },
    setUserList(state, action: PayloadAction<object>) {
      state.userList.push(action.payload)
    },
    setEmptyUserList(state) {
      state.userList = []
    },
    setShowDeleteModal(state, action: PayloadAction<boolean>) {
      state.ishowDeleteModal = action.payload
    },
    setShowEditModal(state, action: PayloadAction<boolean>) {
      state.isShowEditModal = action.payload
    },
    setCallApi(state, action: PayloadAction<number>) {
      state.callApiUserList = action.payload
    },
    setUserId(state, action: PayloadAction<number>){
        state.userId=action.payload
    },
    setEditUserData(state, action: PayloadAction<object>) {
        state.editUserData=[]
        state.editUserData.push(action.payload)
    },
    setUserPermission(state, action: PayloadAction<string>){
        state.userPermission.push(action.payload)
    },
    removePermission(state, action: PayloadAction<string>){
        const updatedPermission = state.userPermission.filter((item) => item !== action.payload)
        state.userPermission=updatedPermission
    },
    setEmptyAllState(state){
        state.userPermission=[]
        state.editUserData=[]

    },setSelectedUser(state,action:PayloadAction<{id:string,name:string,email:string}>){

      state.selectedUser=[];
      state.selectedUser.push(action.payload)

    },setIshowInvited(state,action:PayloadAction<boolean>){

      state.isShowInvited=action.payload

    },setIshowMySharedSchedule(state,action:PayloadAction<boolean>){

      state.isShowMySharedSchedule=action.payload

    },emptySelectedUserData(state){
      state.selectedUser=[]
    },
    setSelectedUserRole(state,action:PayloadAction<any>){
      state.selectedUserRole=[];
      state.selectedUserRole.push(action.payload)



    },setSelectedUserPermissions(state,action:PayloadAction<any>){
      state.selectedUserPermission=[];
      state.selectedUserPermission.push(action.payload)
    },

    setEmptyRoleAndPermission(state){

     state.userPermission=[]
 
    },
    setEmptyPermission(state){

      state.selectedUserPermission=[];
  
     }, 
    setIsCompanyInvitation(state,action:PayloadAction<boolean>){
      state.isCompnayInvitation=action.payload

    },setIsActionAllowed(state,action:PayloadAction<boolean>){
      state.isActionAllowed=action.payload

    } ,setAddUserLimit(state, action: PayloadAction<number>){
      state.addUserLimit=action.payload
    },
    setIsAddOnActive(state,action:PayloadAction<boolean>){
      state.isAddOnActive=action.payload

    } ,
    setShowInvitationPopUp(state,action:PayloadAction<boolean>){
      state.afterAcceptPopUp=action.payload

    } ,
    
    setPopUpMessage(state,action:PayloadAction<string>){
      state.afterAcceptInvitationMessage=action.payload

    } ,
    setLeaveScheduleModal(state,action:PayloadAction<boolean>){
      state.isShowLeaveScheduleModal=action.payload

    } ,
    setSelectedUserId(state, action: PayloadAction<number>){
      state.selectedUserId=action.payload
    },  setShowResponseLeaveSchedule(state,action:PayloadAction<boolean>){
      state.isShowLeaveScheduleResponse=action.payload

    } , setCompanyUserInvitationCount(state, action: PayloadAction<number>){
      state.companyUserInvitationCount=action.payload


    },
    setSelectUserForEvent(state, action: PayloadAction<any>){
      state.selectUserForEvent=[]
      state.selectUserForEvent.push(action.payload)
    },
    setEmptySelectUserForEvent(state){
      state.selectUserForEvent=[]
    },

    setShowModalForExceedInviteLimit(state,action:PayloadAction<boolean>){
      state.showModalForExceedInviteLimit=action.payload
    }
    


  },
})

export default UsermanagementSlice.reducer

export const {
  setAddUserModal,
  setUserList,
  setEmptyUserList,
  setShowDeleteModal,
  setShowEditModal,
  setCallApi,
  setUserId,
  setEditUserData,
  setUserPermission,
  removePermission,
  setEmptyAllState,
  setSelectedUser,
  setIshowInvited,
  setIshowMySharedSchedule,
  emptySelectedUserData,
  setSelectedUserRole,
  setSelectedUserPermissions,
  setEmptyRoleAndPermission,
  setIsCompanyInvitation,
  setIsActionAllowed,
  setEmptyPermission,
  setAddUserLimit,
  setIsAddOnActive,
  setShowInvitationPopUp,
  setPopUpMessage,
  setLeaveScheduleModal,
  setSelectedUserId,
  setShowResponseLeaveSchedule,
  setCompanyUserInvitationCount,
  setSelectUserForEvent,
  setEmptySelectUserForEvent,
  setShowModalForExceedInviteLimit
} = UsermanagementSlice.actions

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  upadNotesId: number
  eventCardId: number
  idsArray: Array<number>
  checkedBox: boolean
  eventID: number
  deleteOnlyNote: boolean
  deleteNoteWithEvent: boolean
  apiStatus: boolean
  apiMessage: string
  isDeleted: boolean
  isCreated: boolean
  CallApi: number
  isUpdated: boolean
  umapFriendsIds:Array<number>
  nonUmapFriendsIds:Array<number>
  editUmapFriendsIds:Array<number>
  editNonUmapFriendsIds:Array<number>
  callApiForList:number

  searchedPinFromMap:boolean
  openPinId:number
  showPopModalForPinPoint:boolean

}
const initialState: IssueInitialState = {
  upadNotesId: 0,
  eventCardId: 0,
  idsArray: [],
  checkedBox: false,
  eventID: -1,
  deleteOnlyNote: false,
  deleteNoteWithEvent: false,
  apiStatus: false,
  apiMessage: 'string',
  isDeleted: false,
  isUpdated: false,
  isCreated: false,
  CallApi: 0,
  umapFriendsIds:[],
  nonUmapFriendsIds:[],
  editUmapFriendsIds:[],
  editNonUmapFriendsIds:[],
  callApiForList:0,
  searchedPinFromMap:true,
  openPinId:0,
  showPopModalForPinPoint:false

}
const UPadNotesIdSlice = createSlice({
  name: 'UPadNotesId',
  initialState,
  reducers: {
    setNoteId(state, action: PayloadAction<number>) {
      state.upadNotesId = action.payload
    },
    setIds(state, action: PayloadAction<{id: number; checked: boolean}>) {
    

      state.checkedBox = action.payload.checked
    
      if (action.payload.checked && !state.idsArray.includes(action.payload.id)) {
  
        state.idsArray.push(action.payload.id)
      } else if(!action.payload.checked)  {
        const indexToRemove = state.idsArray.indexOf(action.payload.id)
        if (indexToRemove !== -1) {
          state.idsArray.splice(indexToRemove, 1)
        }
      }

 
      // or [...idArray]

      // state.eventCardId = action.payload
    },

    setCheckBox(state, action: PayloadAction<{checkBoxValue: boolean}>) {
      // state.checkedBox = action.payload.checkBoxValue;

    },
    SetCardId(state, action: PayloadAction<{id: number}>) {
      state.eventCardId = action.payload.id
    },
    setDeleteNoteId(state, action: PayloadAction<{noteId: number; eventID: number}>) {
      state.upadNotesId = action.payload.noteId
      state.eventID = action.payload.eventID
    },
    setDeleteOnlyNote(state, action: PayloadAction<boolean>) {
      state.deleteOnlyNote = action.payload
    },
    setDeleteNoteWithEvent(state, action: PayloadAction<boolean>) {
      state.deleteNoteWithEvent = action.payload
    },
    setIsDeleted(state, action: PayloadAction<boolean>) {
      state.isDeleted = action.payload
    },
    setApiResponse(state, action: PayloadAction<{apiStatus: boolean; apiMessage: string}>) {
      state.apiStatus = action.payload.apiStatus
      state.apiMessage = action.payload.apiMessage
    },
    setCallApi(state, action: PayloadAction<number>) {
      state.CallApi = action.payload
    },
    setIsUpdated(state, action: PayloadAction<boolean>) {
      state.isUpdated = action.payload
    },
    setIsCreated(state, action: PayloadAction<boolean>) {
      state.isCreated = action.payload
    },
    setEditGroupIds(state, action: PayloadAction<{id:number,checked:boolean,type:string}>){
      state.checkedBox = action.payload.checked;

      if (action.payload.type==='umap') {
        if (action.payload.checked && !state.editUmapFriendsIds.includes(action.payload.id)) {

          state.editUmapFriendsIds.push(action.payload.id); 
        }
        else if(!action.payload.checked) {

          const indexToRemove = state.editUmapFriendsIds.indexOf(action.payload.id)
          if (indexToRemove !== -1) {
            state.editUmapFriendsIds.splice(indexToRemove,1)
          }
        } 

      }
      else if (action.payload.type==='non-umap') {
        if (action.payload.checked && !state.editNonUmapFriendsIds.includes(action.payload.id)) {

          state.editNonUmapFriendsIds.push(action.payload.id); 
        }
        else if(!action.payload.checked) {
  
          const indexToRemove = state.editNonUmapFriendsIds.indexOf(action.payload.id)
          if (indexToRemove !== -1) {
            state.editNonUmapFriendsIds.splice(indexToRemove,1)
          }
        } 
   

      }
    },
    setCallApiForlist(state, action: PayloadAction<number>){
  state.callApiForList=action.payload
    },
    setGroupIds(state, action: PayloadAction<{id:number,checked:boolean,type:string}>){
      state.checkedBox = action.payload.checked;
  
      if (action.payload.type==='umap') {
        if (action.payload.checked) {
     
          state.umapFriendsIds.push(action.payload.id); 
        }
        else{
          const indexToRemove = state.umapFriendsIds.indexOf(action.payload.id)
          if (indexToRemove !== -1) {
            state.umapFriendsIds.splice(indexToRemove,1)
          }
        } 
      }
      else if (action.payload.type==='non-umap') {
        if (action.payload.checked) {
    
          state.nonUmapFriendsIds.push(action.payload.id); 
        }
        else{
          const indexToRemove = state.nonUmapFriendsIds.indexOf(action.payload.id)
          if (indexToRemove !== -1) {
            state.nonUmapFriendsIds.splice(indexToRemove,1)
          }
        } 
      }
    },
    setToEmptyList(state){
     state.idsArray=[]
    },setOpenPinPointToolBar(state,action:PayloadAction<boolean>){
      state.searchedPinFromMap=action.payload

    },setOpenPinId(state,action:PayloadAction<number> ){
      state.openPinId=action.payload

    },setShowPopUpModalForpin(state,action:PayloadAction<boolean>){
      state.showPopModalForPinPoint=action.payload

    }

  },
})

export default UPadNotesIdSlice.reducer

export const {
  setNoteId,
  setIds,
  setCheckBox,
  SetCardId,
  setDeleteNoteId,
  setDeleteOnlyNote,
  setDeleteNoteWithEvent,
  setIsDeleted,
  setApiResponse,
  setCallApi,
  setIsUpdated,
  setIsCreated,
  setGroupIds,
  setEditGroupIds,
  setCallApiForlist,
  setToEmptyList,
  setOpenPinPointToolBar,
  setOpenPinId,
  setShowPopUpModalForpin
} = UPadNotesIdSlice.actions

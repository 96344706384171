import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const SearchDateDropDownSlice=createSlice({
    name:"ShareScheduleDateDropDown",
    initialState,
    reducers:{
        setSearchDateItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getSearchDateItem(state,action){
       
        }
        
    }


});


export default SearchDateDropDownSlice.reducer

export const {setSearchDateItem,getSearchDateItem}=SearchDateDropDownSlice.actions;
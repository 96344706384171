import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string,
    eventHistoryTabValue:string,
    eventHistoryTabValueForDel:string,
    CallApi:number,
    eventExistFriends:boolean
}
const initialState: IssueInitialState = {
    type:"",
    eventHistoryTabValue:'',
    eventHistoryTabValueForDel:'',
    CallApi:0,
    eventExistFriends:false
}
const EventHistoryDropDownSlice=createSlice({
    name:"EventHistoryDropDown",
    initialState,
    reducers:{
        setItem(state, action: PayloadAction<string>){
             state.type=action.payload
       
             /* 
            return ;  */
        },
        setEventHistoryTabValue(state, action: PayloadAction<string>){
            state.eventHistoryTabValue=action.payload
            
       },
        setEventHistoryTabValueForDel(state, action: PayloadAction<string>){
            state.eventHistoryTabValueForDel=action.payload
            
       },
       setCallApiForEventHistory(state,action:PayloadAction<number>){
        state.CallApi=action.payload

       },
       setEevntExistFriends(state,action:PayloadAction<boolean>){
        state.eventExistFriends=action.payload

       }
        
    }


});

export default EventHistoryDropDownSlice.reducer

export const {setItem,setEventHistoryTabValue,setCallApiForEventHistory,setEventHistoryTabValueForDel , setEevntExistFriends}=EventHistoryDropDownSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
   
    type:string,
    month: string,
    year:number,
    days:string
}
const initialState: IssueInitialState = {
    
    type:"",
    month: "",
    year:0,
    days:""
}
const SearchDateDropDownSlice=createSlice({
    name:"SearchDateDropDown",
    initialState,
    reducers:{
        setSearchDateItem(state, action: PayloadAction<{month:string,year:number,days:string,type:string}>){
            state.type=action.payload.type
             state.month=action.payload.month
             state.year=action.payload.year
             state.days=action.payload.days
            

            
        },
        getSearchDateItem(state,action){
  
        }
        
    }


});


export default SearchDateDropDownSlice.reducer

export const {setSearchDateItem,getSearchDateItem}=SearchDateDropDownSlice.actions;
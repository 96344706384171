import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    loading: boolean
 
}
const initialState: IssueInitialState = {
    loading:false,
  
}
const LoadingSpinerSlice=createSlice({
    name:"LoadingSpinerSlice",
    initialState,
    reducers:{
   
        setLoadingSpiner(state,action:PayloadAction<boolean>){
            state.loading=action.payload

        }
        
    }


});

export default LoadingSpinerSlice.reducer

export const {setLoadingSpiner}=LoadingSpinerSlice.actions;
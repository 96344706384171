import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    sectionValue: string
}
const initialState: IssueInitialState = {
    sectionValue:''
}
const SectionSlice=createSlice({
    name:"Section",
    initialState,
    reducers:{
        setSection(state, action: PayloadAction<string>){
             state.sectionValue=action.payload
        },
        
    }


});


export default SectionSlice.reducer

export const {setSection}=SectionSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const SearchCategoryDropDownSliceForHome=createSlice({
    name:"SearchCategoryDropDown",
    initialState,
    reducers:{
        setSearchCategoryItemForHome(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
  
        
    }


});


export default SearchCategoryDropDownSliceForHome.reducer

export const {setSearchCategoryItemForHome}=SearchCategoryDropDownSliceForHome.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const UpadNoteDropDownSlice=createSlice({
    name:"NoteDropDownSlice",
    initialState,
    reducers:{
        setNoteSelectedItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getNoteSelectedItem(state,action){

        }
        
    }


});


export default UpadNoteDropDownSlice.reducer

export const {setNoteSelectedItem,getNoteSelectedItem}=UpadNoteDropDownSlice.actions;
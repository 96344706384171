import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const SearchCategoryDropDownSlice=createSlice({
    name:"ShareScheduleCategoryDropDown",
    initialState,
    reducers:{
        setSearchCategoryItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getSearchCategoryItem(state,action){
       
        }
        
    }


});


export default SearchCategoryDropDownSlice.reducer

export const {setSearchCategoryItem,getSearchCategoryItem}=SearchCategoryDropDownSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: boolean
}
const initialState: IssueInitialState = {
    type:false
}
const AddTripSlice=createSlice({
    name:"MenuBar",
    initialState,
    reducers:{
        setAddTrip(state, action: PayloadAction<boolean>){
             state.type=action.payload
        },
        
    }


});


export default AddTripSlice.reducer

export const {setAddTrip}=AddTripSlice.actions;
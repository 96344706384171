import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    isShow: boolean
    status:boolean
    isGroupShow: boolean
    GroupStatus:boolean
    isLogin:boolean
}
const initialState: IssueInitialState = {
    isShow:false,
    status:false,
    isGroupShow:false,
    GroupStatus:false,
    isLogin:false
}
const MessageModalSlice=createSlice({
    name:"MessageModalSlice",
    initialState,
    reducers:{
        setMessageModalState(state, action: PayloadAction<{isShow:boolean,status:boolean}>){
             state.isShow=action.payload.isShow
             state.status=action.payload.status
   
        },
        setGroupMessageModalState(state, action: PayloadAction<{isShow:boolean,status:boolean}>){
             state.isGroupShow=action.payload.isShow
             state.GroupStatus=action.payload.status
        },
        setIsLogin(state,action:PayloadAction<boolean>){
            state.isLogin=action.payload

        }
        
    }


});

export default MessageModalSlice.reducer

export const {setMessageModalState,setGroupMessageModalState,setIsLogin}=MessageModalSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
    screen:string
}
const initialState: IssueInitialState = {
    type:"Umaps Schedule View",
    screen:"share"
}
const ScheduleViewSlice=createSlice({
    name:"ScheduleView",
    initialState,
    reducers:{
        setView(state, action: PayloadAction<{type:string,screen:string}>){
             state.type=action.payload.type
             state.screen=action.payload.screen
           
        },
        getView(state,action){
     
        }
        
    }


});


export default ScheduleViewSlice.reducer

export const {setView,getView}=ScheduleViewSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    isSubmit: boolean
    color:string
    expDate:string
    QrColor:object,
    QrCode:string,
}
const initialState: IssueInitialState = {
    isSubmit: false,
    color:"",
    expDate:"",
    QrColor:{},
    QrCode:""

  
}
const CustomQrSlice=createSlice({
    name:"CustomQrSlice",
    initialState,
    reducers:{
        setData(state, action: PayloadAction<{isSubmit:boolean;color:string;expDate:string,QrColor:object,QrCode:string}>){
            state.color=action.payload.color
            state.isSubmit=action.payload.isSubmit
            state.expDate=action.payload.expDate
            state.QrColor=action.payload.QrColor
            state.QrCode=action.payload.QrCode
             
            
        },
        setQrColor(state,action:PayloadAction<object>){
            state.QrColor=action.payload
        },
        setSimpleQrCodeData(state, action: PayloadAction<{isSubmit:boolean,expDate:string,QrCode:string}>){
            state.expDate=action.payload.expDate
      
            state.QrCode=action.payload.QrCode
            state.isSubmit=action.payload.isSubmit
        },
        setTime(state,action:PayloadAction<string>){

             state.expDate=action.payload

        }
       
        
    }


});

export default CustomQrSlice.reducer

export const {setData,setQrColor,setSimpleQrCodeData,setTime}=CustomQrSlice.actions;
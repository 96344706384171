// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics';

import {getMessaging, getToken, isSupported, onMessage} from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}



const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider()




const messaging = (async () => {
  try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          return getMessaging(app);
      }
      return null;
  } catch (err) {
      console.error(err);
      return null;
  }
})();

export async function requestPermission() {
  if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
      try {
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
              const messagingInstance = await messaging; // Wait for the messaging promise to resolve
              if (messagingInstance) {
                  const token = await getToken(messagingInstance, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
                  localStorage.setItem('fcm_token', token);
              } else {
              }
     
          } else if (permission === 'denied') {
              console.warn('Notification permission denied');
          }
      } catch (error) {
          console.error('Error requesting notification permission:', error);
      }
  } else {
      console.error('This browser does not support Firebase Cloud Messaging.');
  }
}



const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications.');
      reject(new Error('Notifications not supported.'));
      return;
    }

    onMessage(getMessaging(), (payload) => {
      try {
        const notificationTitle = payload?.notification?.title || 'Default Title';
        const notificationOptions = {
          body: payload?.notification?.body,
          icon: '/media/home/Logo.png',
        };

        if (Notification.permission === 'granted') {
          new Notification(notificationTitle, notificationOptions);
        }

        resolve(payload);
      } catch (error) {
        console.error('Error handling incoming message:', error);
        reject(error);
      }
    });
  });




function isSafari() {
  return false 
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

if (!isSafari()) {
  // Only run the functions if the browser is not Safari
  //requestPermission();

  const onMessageListenerPromise = onMessageListener();
  onMessageListenerPromise
    .then(() => {
      console.log('Initialized ');
    })
    .catch((error) => {
      console.error('Error initializing onMessageListener:', error);
    });
} else {
  console.warn('Functions not executed on Safari.');
}


export {auth, provider,analytics}
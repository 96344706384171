import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    time: string
}
const initialState: IssueInitialState = {
    time:""
}
const QrCodeTimeSlice=createSlice({
    name:"QrCodeTime",
    initialState,
    reducers:{
        setTime(state, action: PayloadAction<string>){
             state.time=action.payload
            
        },
        getTime(state,action){
       
        }
        
    }


});

export default QrCodeTimeSlice.reducer

export const {setTime,getTime}=QrCodeTimeSlice.actions;
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import './style.css'
import {auth, provider} from './LoginWithGoogle/config'

import {
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import 'firebase/auth'
import firebase from 'firebase/app'
import {useDispatch, useSelector} from 'react-redux'
import {
  setOperatingSystem,
  setSubAdminsLength,
  setSubscriptionDetail,
  setUshareSubscriptionId,
} from '../../../../store/slices/UserSubscriptionDetail'
import {RootState} from '../../../../store'
import {setIsAddOnActive} from '../../../../store/slices/User_management/UserManagementSlice'
import {MessageModal} from '../../event/components/MessageModal'
import {setMessageModalState} from '../../../../store/slices/MessageModalSlice'
import { setCurrentLocationLatLng } from '../../../../store/slices/EventDetailSlice'

const signUpSchema = Yup.object({
  userName: Yup.string()
    .min(4, 'username must be at least 4 characters')
    .required('User name is required')
    .matches(
      /^(?=.*[A-Z])(?=.*\d).+$/,
      'Username must contain at least one uppercase letter and one number'
    ),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string()
    .min(7)
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%*#?&]{7,}$/,
      'Password must contain at least one uppercase letter, one number, and one special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})

const initialValues = {
  userName: '',
  email: '',
  password: '',
  confirmPassword: '',
}
export function Registration() {
  const [serverNameError, setServerNameError] = useState('')
  const [serverEmailError, setServerEmailError] = useState('')
  const [togglePasswordIcon,setTogglePasswordIcon] = useState(false)
  const [toggleConfirmPasswordIcon,setToggleConfirmPasswordIcon] = useState(false)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const currentMonth =(currentDate.getMonth() + 1).toString().padStart(2, '0');
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      RegisterUser()
    },
  })

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    const nauthToken = authToken?.replaceAll(/"/g, '')
    if (nauthToken) {
      /*  localStorage.clear() */
      navigate('/schedule/personal_schedule')
    }
  }, [])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
        
          dispatch(setCurrentLocationLatLng({lat: latitude, lng: longitude}))
        },
        (error) => {
          console.error('Error getting geolocation:', error)
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('User denied the request for Geolocation.')
              break
            case error.POSITION_UNAVAILABLE:
              console.error('Location information is unavailable.')
              break
            case error.TIMEOUT:
              console.error('The request to get user location timed out.')
          }
        },
        {
          enableHighAccuracy: true, // Request high accuracy location
          timeout: 10000, // Timeout after 10 seconds
          maximumAge: 0, // No cache
        }
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  },[])

  const RomeveServerError = () => {
    setServerEmailError('')
    setServerNameError('')
  }

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const [checkBox1, setCheckBox1] = useState(false)
  const [checkBox2, setCheckBox2] = useState(false)
  const [signupStatus, setSignupStatus] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const OperatingSystem = localStorage.getItem('operatingSystem')

  const ushareSubscriptionId = useSelector((state: RootState) => {
    return state.UserSubscriptionDetail.ushareSubscriptionId
  })

  const isShow = useSelector((state: RootState) => {
    return state.MessageModalSlice.isShow
  })

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()

    if (userAgent.includes('windows')) {
      localStorage.setItem('operatingSystem', 'google')
    } else if (userAgent.includes('macintosh')) {
      localStorage.setItem('operatingSystem', 'apple')
    } else {
      localStorage.setItem('operatingSystem', 'google')
    }
  }, [])

  useEffect(() => {
    if (!isShow) {
      setSignupStatus(false)
    }
  }, [isShow])

  const SignInWithGoogle = () => {
    signInWithPopup(auth, provider).then((data: any) => {
      RegisterUserWithGoogle(data.user.email, data.user.accessToken, data.user.displayName)
    })
  }

  const SignUpWithApple = async () => {
    try {
      const appleProvider = new OAuthProvider('apple.com')
      // Customize the OAuth provider URL for Sign In with Apple
      appleProvider.setCustomParameters({
        prompt: 'select_account', // This is important for re-authentication
      })

      const result: any = await signInWithPopup(auth, appleProvider)

      // Get user information from the result.user object
      const {displayName, email, uid} = result.user

      // Access the access token from the result.credential.accessToken
      //const accessToken = result.credential.accessToken;

      RegisterUserWithApple(email, uid, displayName)
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const RegisterUserWithApple = async (email: string, provideID: string, username: string) => {
    /* event.preventDefault() */
    setLoading(true)
    /* setTimeout(()=>{
    navigate("/schedule/peronal_schedule");

    },500); */

    let data = {
      username: username.replaceAll(' ', '_'),
      email: email,
      password: formik.values.password,
      device_id: '77777777',
      source: 'web',
      name: username.replaceAll(' ', '_'),
      continue_to_apple: '1',
      provider_id: provideID,
      provider: 'apple',
    }

    let formData = new FormData()
    formData.append('username', data.username)
    formData.append('email', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data.device_id)
    formData.append('source', data.source)
    formData.append('name', data.name)
    formData.append('continue_to_apple', data.continue_to_apple)
    formData.append('provider_id', data.provider_id)
    formData.append('provider', data.provider)

    /*  for (let key in data) {
      formData.append(key, data[key] as string); // Use type assertion here
    } */
    //alert("sign up with apple before api call")
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/registerWithSocial/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      if (response.data.status === true) {
        // If response.status is true, navigate to the next page
        localStorage.setItem('userIsNew', JSON.stringify('true'))
        localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(email))
        localStorage.setItem('token', JSON.stringify(response.data.token))
        localStorage.setItem('name', JSON.stringify(data.username))

        ActiveSubscriptionApi()

        if (response.data.walkthrough_login === null || response.data.walkthrough_login === '') {
          localStorage.setItem('walkthrough_login', response.data.walkthrough_login)

          navigate('/walkthrough')
        } else {
          navigate('/schedule/personal_schedule')
        }

        /* EventList() */
        //alert("sign up with apple before api call success")
      } else {
        // Handle the case where response.status is false (Invalid Credentials)
        // You can display an error message or take appropriate action here
        console.error('Invalid Credentials')
      }
    } catch (error: any) {
      if (error.response) {
        //alert("sign up with apple before api call "+error.response.data)
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data

        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
          if (errorResponse.errors.username) {
            setServerNameError(errorResponse.errors.username)
          }
          if (errorResponse.errors.email) {
            setServerEmailError(errorResponse.errors.email)
          }
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
    }
    setLoading(false)
  }

  const RegisterUserWithGoogle = async (email: string, provideID: string, username: string) => {
    /* event.preventDefault() */
    setLoading(true)
    /* setTimeout(()=>{
    navigate("/schedule/peronal_schedule");

    },500); */
    //alert("sign up with google before api call")
    let data = {
      username: username.replaceAll(' ', '_'),
      email: email,
      password: formik.values.password,
      device_id: '77777777',
      source: 'web',
      name: username.replaceAll(' ', '_'),
      continue_to_google: '1',
      provider_id: provideID,
      provider: 'google',
    }

    let formData = new FormData()
    formData.append('username', data.username)
    formData.append('email', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data.device_id)
    formData.append('source', data.source)
    formData.append('name', data.name)
    formData.append('continue_to_google', '1')
    formData.append('provider_id', data.provider_id)
    formData.append('provider', data.provider)

    /*  for (let key in data) {
      formData.append(key, data[key] as string); // Use type assertion here
    } */

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/registerWithSocial/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      if (response.data.status === true) {
        // If response.status is true, navigate to the next page
        localStorage.setItem('userIsNew', JSON.stringify('true'))
        localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(email))
        localStorage.setItem('token', JSON.stringify(response.data.token))
        localStorage.setItem('name', JSON.stringify(data.username))

        ActiveSubscriptionApi()
        /* EventList() */
        navigate('/schedule/personal_schedule')
      } else {
        // Handle the case where response.status is false (Invalid Credentials)
        // You can display an error message or take appropriate action here
        console.error('Invalid Credentials')
      }
      //alert("sign up with google before api call")
    } catch (error: any) {
      if (error.response) {
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data
        //alert("sign up with google before api call"+errorResponse)

        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
          if (errorResponse.errors.username) {
            setServerNameError(errorResponse.errors.username)
          }
          if (errorResponse.errors.email) {
            setServerEmailError(errorResponse.errors.email)
          }
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
    }
    setLoading(false)
  }
  const RegisterUser = async () => {
    /* event.preventDefault() */
    setLoading(true)
    /* setTimeout(()=>{
    navigate("/schedule/peronal_schedule");

    },500); */
    //alert("sign up  before api call")

    let data = {
      username: formik.values.userName,
      email: formik.values.email,
      password: formik.values.password,
      device_id: '77777777',
      source: 'web',
      name: formik.values.userName,
    }

    let formData = new FormData()
    formData.append('username', data.username)
    formData.append('email', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data.device_id)
    formData.append('source', data.source)
    formData.append('name', data.name)

    /*  for (let key in data) {
      formData.append(key, data[key] as string); // Use type assertion here
    } */

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/register/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      setSignupStatus(response.data.status)

      if (response.data.status === true) {
        // Successful response, navigate to another page
        /*      localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(data.email))
        localStorage.setItem('token', JSON.stringify(response.data.token))
        localStorage.setItem('name', JSON.stringify(data.username))
        localStorage.setItem('password', JSON.stringify(data.password))
        localStorage.setItem('userIsNew', JSON.stringify('true')) */

        ActiveSubscriptionApi()

        dispatch(setMessageModalState({isShow: true, status: true}))

        //alert("sign up  after api call")
        // navigate('/signin');
        //navigate('/walkthrough')

        // setTimeout(() => {
        //   navigate('/schedule/personal_schedule')
        // }, 1000)
      }
    } catch (error: any) {
      if (error.response) {
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data

        console.error('Error response:', errorResponse)
        //alert("sign up  after api call"+errorResponse)
        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
          if (errorResponse.errors.username) {
            setServerNameError(errorResponse.errors.username)
          }
          if (errorResponse.errors.email) {
            setServerEmailError(errorResponse.errors.email)
          }
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
    }
    setLoading(false)
  }

  const ActiveSubscriptionApi = async () => {
    try {
      const authToken = localStorage.getItem('token')
      const nauthToken = authToken?.replaceAll(/"/g, '')

      if (!authToken) {
        console.error('Authentication token is missing.')
        return
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/getCurrentSubscription/${userTimeZone.replaceAll("/","_").toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
      // dispatch(setSubscripitionPlanType())
      dispatch(setUshareSubscriptionId(response.data?.featuresAllowed[0]?.subscription_plan_id))
      dispatch(setSubscriptionDetail(response.data))

      response?.data?.featuresAllowed.forEach((value: any) => {
        if (value?.feature?.name.includes('sub_admin')) {
          const getSubAdmins = value?.feature?.name?.split('_')
          const no_subAdmins = getSubAdmins[0]
          if(response?.data?.trial_days_remaining > 0 && (response.data?.featuresAllowed[0]?.subscription_plan_id == 1 || response.data?.featuresAllowed[0]?.subscription_plan_id  == 5)){
            dispatch(setSubAdminsLength(parseInt("5")))
          }else{
            dispatch(setSubAdminsLength(parseInt(no_subAdmins)))
          }
          
        }
      })

      getUsageCostPlans()
      getAiCostPlans()
    } catch (error: any) {}
  }

  const getUsageCostPlans = async () => {
    try {
      const authToken = localStorage.getItem('token')
      const nauthToken = authToken?.replaceAll(/"/g, '')

      if (!authToken) {
        console.error('Authentication token is missing.')
        return
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/getUsageCostPlans`,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )

      addMapUsageCostAPi(response.data.usageCostPlans)
    } catch (error) {}
  }

  const addMapUsageCostAPi = async (usageCostPlans: any) => {
    const {free_user, paid_user_1, paid_user_2, paid_user_3} = usageCostPlans
    let planAmount = ''

    const authToken = localStorage.getItem('token')
    const nauthToken = authToken?.replaceAll(/"/g, '')

    if (!authToken) {
      console.error('Authentication token is missing.')
      return
    }

    if (OperatingSystem === 'google') {
      switch (ushareSubscriptionId) {
        case 1:
          planAmount = free_user
          break
        case 2:
          planAmount = paid_user_1
          break
        case 3:
          planAmount = paid_user_2
          break
        case 4:
          planAmount = paid_user_3
          break
        default:
          break
      }
    }
    const formData = new FormData()
    formData.append('amount', planAmount)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/addMapUsageCost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
    } catch (error) {}
  }

  const getAiCostPlans = async () => {
    try {
      const authToken = localStorage.getItem('token')
      const nauthToken = authToken?.replaceAll(/"/g, '')

      if (!authToken) {
        console.error('Authentication token is missing.')
        return
      }
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/getAiCostPlans`, {
        headers: {
          Authorization: `Bearer ${nauthToken}`,
        },
      })

      addAiUsageCost(response.data.aiUsageCostPlans)
    } catch (error) {}
  }

  const addAiUsageCost = async (aiUsageCostPlans: any) => {
    const {free_user, paid_user_1, paid_user_2, paid_user_3} = aiUsageCostPlans
    let allowed_tokens = 0
    let allowed_words = 0

    const authToken = localStorage.getItem('token')
    const nauthToken = authToken?.replaceAll(/"/g, '')

    if (!authToken) {
      console.error('Authentication token is missing.')
      return
    }
    switch (ushareSubscriptionId) {
      case 1:
        allowed_tokens = free_user.allowed_tokens
        allowed_words = free_user.allowed_words
        break
      case 2:
        allowed_tokens = paid_user_1.allowed_tokens
        allowed_words = paid_user_1.allowed_words
        break
      case 3:
        allowed_tokens = paid_user_2.allowed_tokens
        allowed_words = paid_user_2.allowed_words
        break
      case 4:
        allowed_tokens = paid_user_3.allowed_tokens
        allowed_words = paid_user_3.allowed_words
        break
      case 5:
        allowed_tokens = free_user.allowed_tokens
        allowed_words = free_user.allowed_words
        break
      case 6:
        allowed_tokens = paid_user_1.allowed_tokens
        allowed_words = paid_user_1.allowed_words
        break
      case 7:
        allowed_tokens = paid_user_2.allowed_tokens
        allowed_words = paid_user_2.allowed_words
        break
      case 8:
        allowed_tokens = paid_user_3.allowed_tokens
        allowed_words = paid_user_3.allowed_words
        break
      default:
        break
    }

    const formData = new FormData()
    formData.append('tokens', String(allowed_tokens))
    formData.append('words', String(allowed_words))

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/addAiUsageCost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
    } catch (error) {}
  }

  const inputFIle = {
    fontSize: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
  }

  let styleForFormIcon = {
    maindiv: {
      position: 'relative',
      display: 'flex',
      /* justifyContent: "end",
      alignItems: "end", */
    },
    icon: {
      position: 'absolute',
      top: '12px',
      /* left: "97%", */

      color: '#4b00ff',
      height: '24px',
      width: '24px',
      display: 'flex',
      /* marginRight: "10px", */
      marginRight: '50px',
    },
  }
  const styleForInput = {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    paddingLeft: '50px',
    color: 'rgba(189, 189, 189, 1)',
    /* width: "458px", */
    height: '48px',
    borderRadius: '8px',
    /* width:"600px" */
  }
  useEffect(() => {
    dispatch(setIsAddOnActive(false))
  }, [])

  function togglePassword() {
    setTogglePasswordIcon(!togglePasswordIcon)
    var x = document.getElementById("password")as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function toggleConfirmPassword() {
    setToggleConfirmPasswordIcon(!toggleConfirmPasswordIcon)
    var x = document.getElementById("confirmPassword")as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        /*    onSubmit={handleSubmit} */
        onSubmit={formik.handleSubmit}
      >
        <div className='d-flex justify-content-center' style={{marginBottom: '48px'}}>
          <h2
            className='text-white'
            /* style={{marginLeft:"45px"}} */ style={{fontSize: '24px', fontWeight: '600'}}
          >
            Create New Account
          </h2>
        </div>
        <div className='input-wrapper'>
          <div className='fv-row  ' style={{marginBottom: '16px'}}>
            <div
              className='col-12 form-input'
              style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
            >
              <input
                type='text'
                className='form-control custom-input'
                style={{...styleForInput}}
                placeholder='Username*'
                id='userName'
                name='userName'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                /*      value={formik.values.userName} */
                onClick={RomeveServerError}
              />

              <img
                src={toAbsoluteUrl('/media/Home/user.png')}
                alt=''
                style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
              />
            </div>
            {formik.errors.userName && formik.touched.userName ? (
              <div className='alert-text font-weight-bold text-danger'>
                {formik.errors.userName}
              </div>
            ) : null}
            {serverNameError ? (
              <div className='alert-text font-weight-bold text-danger'>{serverNameError}</div>
            ) : (
              ''
            )}
          </div>

          {/* begin::Form group Email */}
          <div className='fv-row' style={{marginBottom: '16px'}}>
            <div
              className='col-12 form-input'
              style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
            >
              <input
                type='email'
                className='form-control'
                style={{...styleForInput}}
                placeholder='Email*'
                id='email'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onClick={RomeveServerError}
              />

              <img
                src={toAbsoluteUrl('/media/Home/email.png')}
                alt=''
                style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
              />
            </div>
            {formik.errors.email && formik.touched.email ? (
              <div className='alert-text font-weight-bold text-danger'>{formik.errors.email}</div>
            ) : null}
            {serverEmailError ? (
              <div className='alert-text font-weight-bold text-danger'>{serverEmailError}</div>
            ) : (
              ''
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='fv-row ' data-kt-password-meter='true' style={{marginBottom: '16px'}}>
            <div
              className='col-12 form-input'
              style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
            >
              <input
                type='password'
                className='form-control'
                style={{...styleForInput}}
                placeholder='Password*'
                id='password'
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />

              <img
                src={toAbsoluteUrl('/media/Home/lock.png')}
                alt=''
                style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
              />
              {togglePasswordIcon ?
                <img
                  className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/openEye.svg')}
                    alt=''
                    style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
                    onClick={togglePassword}
                  />
                  :
                  <img
                  className='cursor-pointer'
                    src={toAbsoluteUrl('/media/Home/closeEye.svg')}
                    alt=''
                    style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
                    onClick={togglePassword}
                  />
                  }
            </div>
            {formik.errors.password && formik.touched.password ? (
              <div className='alert-text font-weight-bold text-danger'>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row ' style={{marginBottom: '32px'}}>
            <div
              className='col-12 form-input'
              style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
            >
              <input
                type='password'
                className='form-control'
                style={{...styleForInput}}
                placeholder='Confirm Password*'
                id='confirmPassword'
                name='confirmPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />

              <img
                src={toAbsoluteUrl('/media/Home/lock.png')}
                alt=''
                style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
              />
               {toggleConfirmPasswordIcon ?
                  <img
                className='cursor-pointer'
                  src={toAbsoluteUrl('/media/Home/openEye.svg')}
                  alt=''
                  style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
                  onClick={toggleConfirmPassword}
                />
                :
                <img
                className='cursor-pointer'
                  src={toAbsoluteUrl('/media/Home/closeEye.svg')}
                  alt=''
                  style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
                  onClick={toggleConfirmPassword}
                />
                }
            </div>

            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
              <div className='alert-text font-weight-bold text-danger'>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
        </div>
        <div className='row' style={{marginTop: '16px'}}>
          <div className='col-1 '>
            {!checkBox1 ? (
              <img
                src={toAbsoluteUrl('/media/Home/unchecked.png')}
                alt=''
                id={"firstUnchecked"}
                className='cursor-pointer checkbox_uncheck_1'
                onClick={() => setCheckBox1(true)}
              />
            ) : (
              ''
            )}
            <input type='checkbox' hidden />
            {checkBox1 ? (
              <img
                src={toAbsoluteUrl('/media/Home/checked.png')}
                alt=''
                id={'firstChecked'}
                className='cursor-pointer checkbox_check_1'
                onClick={() => setCheckBox1(false)}
              />
            ) : (
              ''
            )}
          </div>
          <div className='col-11'>
            <div className='' style={{color: 'white', fontSize: '16px', fontWeight: '400'}}>
              You must be at least 13 years old to sign up.{' '}
            </div>
          </div>
        </div>
        <div className='row' style={{marginTop: '16px'}}>
          <div className='col-1 '>
            {!checkBox2 ? (
              <img
                src={toAbsoluteUrl('/media/Home/unchecked.png')}
                alt=''
                id={"secondUnchecked"}
                className='cursor-pointer checkbox_uncheck_1'
                onClick={() => setCheckBox2(true)}
              />
            ) : (
              ''
            )}
            <input type='checkbox' hidden />
            {checkBox2 ? (
              <img
                src={toAbsoluteUrl('/media/Home/checked.png')}
                alt=''
                id={'secondChecked'}
                className='cursor-pointer checkbox_check_1'
                onClick={() => setCheckBox2(false)}
              />
            ) : (
              ''
            )}
          </div>

        {/*   <div className='col-11'>
            <div className='' style={{color: 'white', fontSize: '16px', fontWeight: '400'}}>
              By signing up, you accept and agree to Umap’s{' '}
              <Link to='/disclaimer' className='link' style={{color: 'red'}}>
                Disclaimer
              </Link>
              ,
              <Link to='/privacy' className='link' style={{color: 'red'}}>
                {' '}
                Terms of Use
              </Link>
              ,
              <Link to='/privacy' className='link' style={{color: 'red'}}>
                {' '}
                Acceptable Use
              </Link>
              ,
              <Link to='/privacy' className='link' style={{color: 'red'}}>
                {' '}
                and Privacy Policy
              </Link>
              .
            </div>
          </div> */}
            <div className='col-11'>
            <div className='' style={{color: 'white', fontSize: '16px', fontWeight: '400'}}>
              By signing up, you accept and agree to Umap’s{' '}
         {/*      <Link to='/disclaimer' className='link' style={{color: 'red'}}>
                Disclaimer
              </Link> */}
              
              <Link to='/termofuse' className='link' style={{color: 'red'}}>
                {' '}
                Terms of Use
              </Link>
              
           {/*    <Link to='/privacy' className='link' style={{color: 'red'}}>
                {' '}
                Acceptable Use
              </Link> */}
              {' '}
                and
              <Link to='/privacy' className='link' style={{color: 'red'}}>
                {' '}
                 Privacy Policy
              </Link>
              .
            </div>
          </div>
        </div>

        <div className='text-center' style={{marginTop: '32px'}}>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg  w-100 mb-5'
            /* disabled={formik.isSubmitting || !formik.isValid} */
            disabled={checkBox1 && checkBox2 ? false : true}
            style={{
              background: 'rgba(229, 9, 20, 1)',
              height: '48px',
            }}
            /* onClick={RegisterUser} */
          >
            {!loading && (
              <span
                className='indicator-label'
                style={{color: 'white', fontSize: '16px', fontWeight: '600'}}
              >
                Sign Up
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <div
            className=' text-center'
            style={{color: '#828282', fontSize: '16px', fontWeight: '500'}}
          >
            Or create account with{' '}
          </div>
          <div className='row g-3' style={{marginTop: '32px'}}>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
              {/* begin::Google link */}
              <a
                href='#'
                className='btn btn-flex btn-outline flex-center text-nowrap'
                style={{
                  border: '1px solid rgba(255, 255, 255, 0.24)',
                  width: '72%', // Adjust the width as needed
                  // Add margin to create space between buttons
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                onClick={SignInWithGoogle}
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/event/google.png')}
                  className='h-24px me-3'
                />
                Google
              </a>
              {/* end::Google link */}
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline flex-center text-nowrap'
                style={{
                  border: '1px solid rgba(255, 255, 255, 0.24)',
                  width: '72%', // Adjust the width as needed
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                onClick={SignUpWithApple}
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/event/Apple.png')}
                  className='h-24px me-3'
                />
                Apple
              </a>
            </div>
          </div>
        </div>

        <div
          className=' text-center '
          style={{color: 'white', marginTop: '17px', fontSize: '16px', fontWeight: '500'}}
        >
          Already have an account?{' '}
          <Link to='/signin' className='link' style={{color: 'red'}}>
            Sign In
          </Link>
        </div>
      </form>
      {signupStatus && (
        <MessageModal
          title='Account Registered'
          imgPath=''
          response={true}
          message={`Your account registered successfully.\nPlease verify your account through email verification and login with your credentials.`}
          show={true}
          returnPath='/signin'
        />
      )}
    </>
  )
}

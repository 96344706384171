import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    requestCount: number,
    appleHitsCount:number,
    requestcountForDirection:number
    requestCountForUtrip:number
}
const initialState: IssueInitialState = {
    requestCount:0,
    appleHitsCount:0,
    requestcountForDirection:0,
    requestCountForUtrip:0
}
const HandleVideoAdSlice=createSlice({
    name:"HandleVideoAdSlice",
    initialState,
    reducers:{
        setRequestCount(state, action: PayloadAction<number>){
             state.requestCount=action.payload
        },   
        setAppleHitsCount(state, action: PayloadAction<number>){
             state.appleHitsCount=action.payload
        },
        setRequestCountForDirection(state,action:PayloadAction<number>){
         state.requestcountForDirection=action.payload
        },
        setRequestCountForUtrip(state,action:PayloadAction<number>){
            state.requestCountForUtrip=action.payload
        }   
    }
});


export default HandleVideoAdSlice.reducer

export const {setRequestCount,setAppleHitsCount,setRequestCountForDirection,setRequestCountForUtrip}=HandleVideoAdSlice.actions;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Message {
    message: string;
    sender: string;
}

export interface IssueInitialState {
    messages: Message[];
}

const initialState: IssueInitialState = {
    messages: [
        {
            message: "Hello, I'm Ushare Ai! Ask me anything!",
            sender: 'ChatGPT',
        },
    ],
};

const AiChatSlice = createSlice({
    name: "AiChatSlice",
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<Message>) {
            state.messages.push(action.payload);
        },
        clearAllMessage(state){
            state.messages=[]
        }
    }
});

export default AiChatSlice.reducer;

export const { setMessage ,clearAllMessage} = AiChatSlice.actions;

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
 /*  ScanQrData(arg0: string, QrString: string): unknown */
  show: boolean
  modalName: string
  LocatedScreen: boolean
  isEventLocated: boolean
  showScanModal: boolean
  ShowEventLocatedModal: boolean
  ScanQrResponse: boolean
  ShowLocatedEventScreen: boolean
  QrString: string
  idsArray:Array<number>
  EventDetailId:number
  showEventDetailPage:boolean
  isOneEvent:boolean,
  friendUserId:number,
  friendName:string,
  medium:string
}
const initialState: IssueInitialState = {
  show: false,
  modalName: '',
  LocatedScreen: false,
  isEventLocated: false,
  showScanModal: false,
  ShowEventLocatedModal: false,
  ScanQrResponse: false,
  ShowLocatedEventScreen: false,
  QrString: '',
  idsArray:[],
  EventDetailId:0,
  showEventDetailPage:false,
  isOneEvent:false,
  friendUserId:0,
  friendName:"",
  medium:""
}
const ScanFriendModalSlice = createSlice({
  name: 'ScanFriendModal',
  initialState,
  reducers: {
    setModalInfo(
      state,
      action: PayloadAction<{show: boolean; modalName: string; LocatedScreen: boolean}>
    ) {
      state.show = action.payload.show
      state.modalName = action.payload.modalName
      state.LocatedScreen = action.payload.LocatedScreen
    },
    setEventLocated(state, action: PayloadAction<boolean>) {
      state.isEventLocated = action.payload
    },
    setShowScanModal(state, action: PayloadAction<boolean>) {
      state.showScanModal = action.payload
    },
    setShowEventLocatedModal(state, action: PayloadAction<boolean>) {
      state.ShowEventLocatedModal = action.payload
    },
    setShowResponse(state, action: PayloadAction<boolean>) {
      state.ScanQrResponse = action.payload
    },
    setShowLocatedEventScreen(state, action: PayloadAction<boolean>) {
      state.ShowLocatedEventScreen = action.payload
    },
    setQrString(state, action: PayloadAction<string>) {
      state.QrString = action.payload
    },
    setEventId(state, action: PayloadAction<number>) {
        state.idsArray.push(action.payload)
      },
    removeId(state, action: PayloadAction<number>) {
        state.idsArray = state.idsArray.filter((id) => id !== action.payload)
    },

    setEevntDetailId(state,action:PayloadAction<number>){

        state.EventDetailId=action.payload
    },
    setEventDetailPage(state,action:PayloadAction<boolean>){
      state.showEventDetailPage=action.payload
    },
    setIsOneEvent(state,action:PayloadAction<boolean>){
      state.isOneEvent=action.payload
    },
    setStatesEmpty(state){
        state.show= false;
        state.modalName= '';
        state.LocatedScreen= false;
        state.isEventLocated= false;
        state.showScanModal= false;
        state.ShowEventLocatedModal= false;
        state.ScanQrResponse= false;
        state.ShowLocatedEventScreen= false;
        state.QrString= '';
        state.idsArray=[];
        state.isOneEvent=false
    },
    setFriendNavigationDetails(state,action:PayloadAction<{friendUserId:number,friendName:string}>){
      state.friendUserId=action.payload.friendUserId
      state.friendName=action.payload.friendName

    },
    setSharingMedium(state,action:PayloadAction<string>){
      state.medium=action.payload
    },
    
  },
})

export default ScanFriendModalSlice.reducer

export const {
  setModalInfo,
  setEventLocated,
  setShowScanModal,
  setShowEventLocatedModal,
  setShowResponse,
  setShowLocatedEventScreen,
  setQrString,
  setEventId,
  removeId,
  setStatesEmpty,
  setEevntDetailId,
  setEventDetailPage,
  setIsOneEvent,
  setFriendNavigationDetails,
  setSharingMedium
} = ScanFriendModalSlice.actions

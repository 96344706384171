import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {stat} from 'fs/promises'

interface Friend {
  id: number
  name: string
  email: string
  phoneNo: string
  InviteByEmail: boolean
  InviteByPhoneNo: boolean
}
interface UmapFriend {
  name: string
  id: number
}
interface Link {
  id: string;
  value: string;
}
export interface IssueInitialState {
  EventName: string
  EventPrority: string
  EventLongitude:string
  EventLatitude:string
  isShowLocationPage:boolean
  isLocationSelected:boolean
  EventLocation: string
  EventCategory: string
  EventStartDate: string
  EventStartTime: string
  EventEndDate: string
  EventEndTime: string
  Link1: string
  Link2: string
  Link3: string
  EventReminderAlertIsCheck: boolean
  EventReminderAlertTime: string
  LocationReminderAlertIsCheck: boolean
  LocationReminderAlertTime: string
  ExtraTimeReminderAlertIsCheck: boolean
  ExtraTimeReminderAlertTime: string
  StartMapReminderAlertIsCheck: boolean
  StartMapReminderAlertTime: string
  EventColor: Object
  TravelBy: string
  InvitedNonUmapFriendsList: Friend[]
  AddInGoogleCalender: boolean
  AddInAppleCalender: boolean
  AddInOutlookCalender: boolean
  Links: Array<string>
  NoteID: number
  EventNote: string
  isShareNote: boolean
  isAddToShared: boolean
  isAddToPersonal: boolean

  isAllFirendAllCheck: boolean
  AllFriendList: Array<object>

  isFavoriteAllCheck: boolean
  FavoriteFriendList: Array<object>

  isGroupAllCheck: boolean
  GroupList: Array<object>

  isPersonalAllCheck: boolean
  PersonalList: Array<object>

  isAllNonUmapFriendCheck: boolean
  NonUmapFriendList: Array<object>

  isNonUmapFavoritCheck: boolean
  NonUmapFavoritFriendList: Array<object>

  isFormSubmit: boolean

  isAddFriendAndGroupFormModal: boolean

  isAddFriendAndGroupFormSubmit: boolean

  UmapFriendsInvitation: Array<object>
  NonUmapFriendsInvitation:Array<object>

  ShowEventDeleteModal:boolean
  recurringEvent:string
  invitedUserId:Array<object>
  InvitedCheckbox:boolean


  removeFileTimeStamp:Array<string>

  showInvitationPopup:boolean,
  linkForEvents:Link[]
  enablePhNo:boolean
  createForInvitedUser:boolean

  addToShared:boolean 
  addToPersonal:boolean 
  addToInvited:boolean
  
}
const initialState: IssueInitialState = {
  EventName: '',
  EventPrority: '',
  EventLocation: '',
  isShowLocationPage:false,
  isLocationSelected:false,
  EventLongitude:'',
  EventLatitude:'',
  EventCategory: '',
  EventStartDate: '',
  EventStartTime: '',
  EventEndDate: '',
  EventEndTime: '',
  Link1: '',
  Link2: '',
  Link3: '',
  EventReminderAlertIsCheck: false,
  EventReminderAlertTime: '',
  LocationReminderAlertIsCheck: false,
  LocationReminderAlertTime: '',
  ExtraTimeReminderAlertIsCheck: false,
  ExtraTimeReminderAlertTime: '',
  StartMapReminderAlertIsCheck: false,
  StartMapReminderAlertTime: '',
  EventColor: {},
  TravelBy: '',
  InvitedNonUmapFriendsList: [],
  AddInGoogleCalender: false,
  AddInAppleCalender: false,
  AddInOutlookCalender: false,
  Links: [],
  NoteID: -1,
  EventNote: '',
  isShareNote: false,
  isAddToShared: false,
  isAddToPersonal: false,

  isAllFirendAllCheck: false,
  AllFriendList: [],

  isFavoriteAllCheck: false,
  FavoriteFriendList: [],

  isGroupAllCheck: false,
  GroupList: [],

  isPersonalAllCheck: false,
  PersonalList: [],

  isAllNonUmapFriendCheck: false,
  NonUmapFriendList: [],

  isNonUmapFavoritCheck: false,
  NonUmapFavoritFriendList: [],

  isFormSubmit: false,
  isAddFriendAndGroupFormModal: false,

  isAddFriendAndGroupFormSubmit: false,

  UmapFriendsInvitation: [],
  NonUmapFriendsInvitation:[],
  ShowEventDeleteModal:false,
  recurringEvent:"",
  invitedUserId:[],
  InvitedCheckbox:false,
  removeFileTimeStamp:[],
  showInvitationPopup:false,
  linkForEvents:[],
  enablePhNo:false,
  createForInvitedUser:false,
  addToInvited:false,
  addToPersonal:false,
  addToShared:false

}
const EveryWeakEventData = createSlice({
  name: 'CreateEventData',
  initialState,
  reducers: {
    setEventName(state, action: PayloadAction<string>) {
      state.EventName = action.payload
    },
    setEventPriority(state, action: PayloadAction<string>) {
      state.EventPrority = action.payload
    },
    setEventLocation(state, action: PayloadAction<string>) {
      state.EventLocation = action.payload
    },
    setEventCategory(state, action: PayloadAction<string>) {
      state.EventCategory = action.payload
    },
    setEventStartDate(state, action: PayloadAction<string>) {
      state.EventStartDate = action.payload
    },
    setEventStartTime(state, action: PayloadAction<string>) {
      state.EventStartTime = action.payload
    },
    setEventReminderAlertIsCheck(state, action: PayloadAction<boolean>) {
      state.EventReminderAlertIsCheck = action.payload
    },
    setEventReminderAlertTime(state, action: PayloadAction<string>) {
      state.EventReminderAlertTime = action.payload
    },
    setLocationReminderAlertIsCheck(state, action: PayloadAction<boolean>) {
      state.LocationReminderAlertIsCheck = action.payload
    },
    setLocationReminderAlertTime(state, action: PayloadAction<string>) {
      state.LocationReminderAlertTime = action.payload
    },
    setExtraTimeReminderAlertIsCheck(state, action: PayloadAction<boolean>) {
      state.ExtraTimeReminderAlertIsCheck = action.payload
    },
    setExtraTimeReminderAlertTime(state, action: PayloadAction<string>) {
      state.ExtraTimeReminderAlertTime = action.payload
    },
    setStartMapReminderAlertIsCheck(state, action: PayloadAction<boolean>) {
      state.StartMapReminderAlertIsCheck = action.payload
    },
    setStartMapReminderAlertTime(state, action: PayloadAction<string>) {
      state.StartMapReminderAlertTime = action.payload
    },
    setTravelBy(state, action: PayloadAction<string>) {
      state.TravelBy = action.payload
    },
    setEventColor(state, action: PayloadAction<{color: object}>) {
      state.EventColor = action.payload.color
    },
    AddNonUmapFriend(state, action: PayloadAction<Friend>) {
      let filterList=state.InvitedNonUmapFriendsList.filter((data)=>{
        return data.email !== action.payload.email

      })
      state.InvitedNonUmapFriendsList=filterList;
      state.InvitedNonUmapFriendsList.push(action.payload)
    },
    setGoogleCalender(state, action: PayloadAction<boolean>) {
      state.AddInGoogleCalender = action.payload
    },
    setAppleCalender(state, action: PayloadAction<boolean>) {
      state.AddInAppleCalender = action.payload
    },
    setOutlookCalender(state, action: PayloadAction<boolean>) {
      state.AddInOutlookCalender = action.payload
    },
    removerFriendFromList(state, action: PayloadAction<number>) {
      state.InvitedNonUmapFriendsList.splice(action.payload, 1)
    },

    setNoteId(state, action: PayloadAction<number>) {
      state.NoteID = action.payload
    },
    setEventLinks(state, action: PayloadAction<{ index: string; value: string }>) {
            state.linkForEvents = state.linkForEvents.map((data: any) => {
        if (data.id.toString() === action.payload.index.toString()) {
          return {
            ...data,
            value: action.payload.value,
          };
        }
        return data;
      }); 
    },
    addNewLink: (state, action: PayloadAction<{ index: string; value: string }>) => {
      // Ensure state and linkForEvents are initialized
      if (!state) {
        state = initialState;
      }

      if (!state.linkForEvents) {
        state.linkForEvents = [];
      }

      state.linkForEvents.push({
        id: action.payload.index,
        value: action.payload.value,
      });
    },
    removeLinkFromArray:(state, action: PayloadAction<string>)=>{
      state.linkForEvents = state.linkForEvents.filter((data: any) => {
        
        return data.id !== action.payload;
      }); 

    },
    
    AddNewLinkIndex(state, action: PayloadAction<string>) {},

    removeAllEventLinks(state) {
      state.Links.splice(0, state.Links.length)

      let data = state.Links.filter((data: any) => {
        return data !== ''
      })
      state.Links = data
    },
    setEventNote(state, action: PayloadAction<string>) {
      state.EventNote = action.payload
    },
    setShareNote(state, action: PayloadAction<boolean>) {
      state.isShareNote = action.payload
    },

    setAddToShared(state, action: PayloadAction<boolean>) {
      state.isAddToShared = action.payload
    },
    setAddToPersonal(state, action: PayloadAction<boolean>) {
      state.isAddToPersonal = action.payload
    },
    setIsCheckAllFriend(state, action: PayloadAction<boolean>) {
      state.isAllFirendAllCheck = action.payload
    },
    setAllFriendsId(state, action: PayloadAction<object>) {
      state.AllFriendList.push(action.payload)
    },
    removeIdFromAllFriendList(state, action: PayloadAction<number>) {
      let data = state.AllFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.AllFriendList = data
    },

    setFavroitFriendsId(state, action: PayloadAction<object>) {
      state.FavoriteFriendList.push(action.payload)
    },
    removeIdFromFavroitFriendsId(state, action: PayloadAction<number>) {
      let data = state.FavoriteFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.FavoriteFriendList = data
    },
    setGroupId(state, action: PayloadAction<any>) {
   


      const id  = action.payload.id;
    
      // Check if the item with the same invited_to_id already exists in the array
      const itemExists = state.GroupList.some(
        (data: any) => data.id === id
      );
    
      if (!itemExists) {
        // If the item doesn't exist, push it into the array
        state.GroupList.push(action.payload)
      }
    },
    removeIdFromGroupList(state, action: PayloadAction<number>) {
        
  
    

      let data = state.GroupList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.GroupList = data
    },
    setPersonalFriendList(state, action: PayloadAction<object>) {
      state.PersonalList.push(action.payload)
    },
    removeIdFromPersonalFriendList(state, action: PayloadAction<number>) {
      let data = state.PersonalList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.PersonalList = data
    },
    setAllNonUmapFriendList(state, action: PayloadAction<object>) {
     /*  let id=(action.payload as any)
      console.log("idid",id?.id)
      let data = state.PersonalList.filter((data: any) => {
        return Number(data.id) == Number(id?.id)
      }) */
    
   /*    if(data.length === 0){ */
        state.NonUmapFriendList.push(action.payload)
    /*   } */
      
    },
    removeIdFromAllNonUmapFriendList(state, action: PayloadAction<number>) {
      let data = state.NonUmapFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.NonUmapFriendList = data
    },

    setNonUmapFavroitFriendList(state, action: PayloadAction<object>) {
      state.NonUmapFavoritFriendList.push(action.payload)
    },
    removeIdFromNonUmapFavroitFriendList(state, action: PayloadAction<number>) {
      let data = state.NonUmapFavoritFriendList.filter((data: any) => {
        return data.id !== action.payload
      })
      state.NonUmapFavoritFriendList = data
    },

    setEventEndDate(state, action: PayloadAction<string>) {
      state.EventEndDate = action.payload
    },
    setEventEndTime(state, action: PayloadAction<string>) {
      state.EventEndTime = action.payload
    },

    setFormSubmit(state, action: PayloadAction<boolean>) {
      state.isFormSubmit = action.payload
    },

    setAddFriendAndGroupModal(state, action: PayloadAction<boolean>) {
      state.isAddFriendAndGroupFormModal = action.payload
    },
    setAddFriendAndGroupFormSubmit(state, action: PayloadAction<boolean>) {
      state.isAddFriendAndGroupFormSubmit = action.payload
    },
    setUmapInvitationIds(state, action: PayloadAction<any>) {
      const id = action.payload.id;
    
      // Check if the item with the same invited_to_id already exists in the array
      const itemExists = state.UmapFriendsInvitation.some(
        (data: any) => data.id === id
      );
    
      if (!itemExists) {
        // If the item doesn't exist, push it into the array
        state.UmapFriendsInvitation.push(action.payload);
      }
    },
    

    removeInvitationId(
      state,
      action: PayloadAction<{index: number; friend_id: number; invitation_id: number}>
    ) {
     /*  let data = state.UmapFriendsInvitation.filter((data: any) => {
        return data.invited_to_id !== action.payload.friend_id
      })
      state.UmapFriendsInvitation = data */
      let data = state.UmapFriendsInvitation.filter((data: any) => {
        return data.id !== action.payload.friend_id
      })
      state.UmapFriendsInvitation = data
    },

    setNonUmapInvitationIds(state, action: PayloadAction<any>) {


      const id = action.payload.id;
    
      // Check if the item with the same invited_to_id already exists in the array
      const itemExists = state.NonUmapFriendsInvitation.some(
        (data: any) => data.id === id
      );
    
      if (!itemExists) {
        // If the item doesn't exist, push it into the array
        state.NonUmapFriendsInvitation.push(action.payload);
      }

    },
    removeNonInvitationId(
      state,
      action: PayloadAction<{index: number; friend_id: number; invitation_id: number}>
    ) {
      let data = state.NonUmapFriendsInvitation.filter((data: any) => {
        return data.id !== action.payload.friend_id
      })
      state.NonUmapFriendsInvitation = data
    },

    setRecurringEvent(state,action:PayloadAction<string>){
       state.recurringEvent=action.payload
    },
    setShowInvitationPopup(state,action:PayloadAction<boolean>){
      state.showInvitationPopup=action.payload
    },
    setEnablePhNo(state,action:PayloadAction<boolean>){
      state.enablePhNo=action.payload
    },

    emptyCreateEventForm(state){
      state.EventName= '';
      state.EventPrority= '';
      state.EventLocation= '';
      state.EventCategory= '';
      state.EventStartDate= '';
      state.EventStartTime= '';
      state.EventEndDate= '';
      state.EventEndTime= '';
      state.Link1= '';
      state.Link2= '';
      state.Link3= '';
      state.EventReminderAlertIsCheck= false;
      state.EventReminderAlertTime= '';
      state.LocationReminderAlertIsCheck= false;
      state.LocationReminderAlertTime= '';
      state.ExtraTimeReminderAlertIsCheck= false;
      state.ExtraTimeReminderAlertTime= '';
      state.StartMapReminderAlertIsCheck= false;
      state.StartMapReminderAlertTime= '';
      state.EventColor= {};
      state.TravelBy= '';
      state.InvitedNonUmapFriendsList= [];
      state.AddInGoogleCalender= false;
      state.AddInAppleCalender= false;
      state.AddInOutlookCalender= false;
      state.Links= [];
      state.NoteID= -1;
      state.EventNote= '';
      state.isShareNote= false;
      state.isAddToShared= false;
      state.isAddToPersonal= false;
    
      state.isAllFirendAllCheck= false;
      state.AllFriendList= [];
    
      state.isFavoriteAllCheck= false;
      state.FavoriteFriendList= [];
    
      state.isGroupAllCheck= false;
      state.GroupList= [];
    
      state.isPersonalAllCheck= false;
      state.PersonalList= [];
    
      state.isAllNonUmapFriendCheck= false;
      state.NonUmapFriendList= [];
    
      state.isNonUmapFavoritCheck= false;
      state.NonUmapFavoritFriendList= [];
    
      state.isFormSubmit= false;
      state.isAddFriendAndGroupFormModal= false;
    
      state.isAddFriendAndGroupFormSubmit= false;
    
      state.UmapFriendsInvitation= [];
      state.NonUmapFriendsInvitation=[];
      state.recurringEvent=""
      state.GroupList=[]

      state.EventLatitude=""
      state.EventLongitude=""
      state.isLocationSelected=false
      state.removeFileTimeStamp=[]
      state.InvitedCheckbox=false
      state.showInvitationPopup=false
      state.linkForEvents = []
      state.enablePhNo=false
    },
     showDeleteEventModal(state, action: PayloadAction<boolean>){

      state.ShowEventDeleteModal=action.payload

    },setLatLag(state, action: PayloadAction<{lng:string,lat:string}>){

      state.EventLatitude=action.payload.lat
      state.EventLongitude=action.payload.lng
    },setShowLocation(state, action: PayloadAction<boolean>){

      state.isShowLocationPage=action.payload

    },setIslocationSelected(state,action:PayloadAction<boolean>){
      state.isLocationSelected=action.payload
    },
    setInvitedUser(state,action:PayloadAction<{id:string,name:string,email:string}>){
      state.invitedUserId=[]
      state.invitedUserId.push(action.payload)
    },
    setInvitedCheckbox(state,action:PayloadAction<boolean>){
      state.InvitedCheckbox=action.payload
      

    },removeGroup(state,action:PayloadAction<string>){

    },
    addRemoveFile(state,action:PayloadAction<string>){
      state.removeFileTimeStamp.push(action.payload)

    }, setCreateForInvitedUser(state,action:PayloadAction<boolean>){
      state.createForInvitedUser=action.payload
      

    },setIsAddToPersonal(state,action:PayloadAction<boolean>){
         state.addToPersonal=action.payload
    },setIsAddToShared(state,action:PayloadAction<boolean>){
      state.addToShared=action.payload
    },setIsAddToInvited(state,action:PayloadAction<boolean>){
      state.addToInvited=action.payload
    }

   
    
  },

 
})

export default EveryWeakEventData.reducer

export const {
  setEventName,
  setEventPriority,
  setEventLocation,
  setEventCategory,
  setEventStartDate,
  setEventStartTime,
  setEventReminderAlertIsCheck,
  setEventReminderAlertTime,
  setLocationReminderAlertIsCheck,
  setLocationReminderAlertTime,
  setExtraTimeReminderAlertIsCheck,
  setExtraTimeReminderAlertTime,
  setStartMapReminderAlertIsCheck,
  setStartMapReminderAlertTime,
  setEventColor,
  AddNonUmapFriend,
  removerFriendFromList,
  setGoogleCalender,
  setAppleCalender,
  setOutlookCalender,
  setTravelBy,
  setNoteId,
  setEventLinks,
  removeAllEventLinks,
  setEventNote,
  setShareNote,
  setAddToShared,
  setAddToPersonal,
  setIsCheckAllFriend,
  setAllFriendsId,
  removeIdFromAllFriendList,
  setFavroitFriendsId,
  removeIdFromFavroitFriendsId,
  setGroupId,
  removeIdFromGroupList,
  setPersonalFriendList,
  removeIdFromPersonalFriendList,
  setAllNonUmapFriendList,
  removeIdFromAllNonUmapFriendList,
  setNonUmapFavroitFriendList,
  removeIdFromNonUmapFavroitFriendList,
  setEventEndDate,
  setEventEndTime,
  setFormSubmit,
  setAddFriendAndGroupModal,
  setAddFriendAndGroupFormSubmit,
  setUmapInvitationIds,
  removeInvitationId,
  emptyCreateEventForm,
  showDeleteEventModal,
  setRecurringEvent,
  setNonUmapInvitationIds,
  removeNonInvitationId,
  setLatLag,
  setShowLocation,
  setIslocationSelected,
  setInvitedUser,
  setInvitedCheckbox,
  addRemoveFile,
  setShowInvitationPopup,
  addNewLink,
  removeLinkFromArray,
  setEnablePhNo,
  setCreateForInvitedUser,
  setIsAddToPersonal,
  setIsAddToShared,
  setIsAddToInvited
} = EveryWeakEventData.actions

import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const MapOfUseDropDown=createSlice({
    name:"MapOfUseDropDown",
    initialState,
    reducers:{
        setMapOfUse(state, action: PayloadAction<string>){
             state.type=action.payload
           
        },
    
        
    }


});


export default MapOfUseDropDown.reducer

export const {setMapOfUse}=MapOfUseDropDown.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
showPopForResendVerficationEmail: boolean
showPopForAccountReactivation: boolean
}
const initialState: IssueInitialState = {
    showPopForResendVerficationEmail: false,
    showPopForAccountReactivation: false
}
const AuthSlice=createSlice({
    name:"AuthSlice",
    initialState,
    reducers:{
    
        setPopForResendEmail(state,action:PayloadAction<boolean>){
            state.showPopForResendVerficationEmail=action.payload

        },
        setPopForAccountActivation(state,action:PayloadAction<boolean>){
            state.showPopForAccountReactivation=action.payload

        }
    
        
    }


});


export default AuthSlice.reducer

export const {setPopForResendEmail,setPopForAccountActivation}=AuthSlice.actions;
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UsersList} from '../../../app/modules/profile/components/UsersList'
import {stat} from 'fs'

export interface IssueInitialState {
  IsShowCreateGroupModal: boolean
  FriendList: Array<object>
  GroupList: Array<object>
  GroupListing: any[]
  roomId: string
  ShowEditModal: boolean
  callApiForGroupChat: number
  ShowDeleteModal: boolean
  ShowLeaveModal:boolean
  openRoomDetail: Array<any>

  removedUserList: Array<any>
  oldAddedFriendList: Array<any>
  groupLimit:number
  isGroupCreate:boolean
  limitExceedPopup:boolean
  FriendAddLimit:number
  characterLimit:number
  characterLimitExceedPopUp:boolean
  friendAddedInEdit:Array<any>
  friendRemovedInEdit:Array<any>
  
  chatLimit:boolean
  isGrowthPlan:boolean
  LimitExceedTitle:string,
  isLocked:boolean
}
const initialState: IssueInitialState = {
  IsShowCreateGroupModal: false,
  FriendList: [],
  GroupList: [],
  GroupListing: [],
  roomId: '',
  ShowEditModal: false,
  callApiForGroupChat: 0,
  ShowDeleteModal: false,
  ShowLeaveModal:false,
  openRoomDetail: [],
  removedUserList: [],
  oldAddedFriendList: [],
  groupLimit:0,
  isGroupCreate:false,
  limitExceedPopup:false,
  FriendAddLimit:0,
  characterLimit:0,
  characterLimitExceedPopUp:false,
  friendAddedInEdit:[],
  friendRemovedInEdit:[],
  chatLimit:false,
  isGrowthPlan:false,
  LimitExceedTitle:"",
  isLocked:false
}
const GroupChatSlice = createSlice({
  name: 'GroupChatSlice',
  initialState,
  reducers: {
    setFriendId(state, action: PayloadAction<any>) {
      const {id, name, color} = action.payload
      
    //maintain list for remove member form list 
 
  

      // Check if the item with the specified id already exists in oldAddedFriendList
      //const isIdExists = state.oldAddedFriendList.some((friend) => friend.id === id)

      /* if (!isIdExists) { */
        // If the id doesn't exist, push the data into the FriendList array
        state.FriendList.push({id, name,color})
     /*  } */

     let idsInOldAddedFriendList = state.oldAddedFriendList.map(data => Number(data?.id));
     let idsInFriendList = state.FriendList.map((data:any) => Number(data?.id));
     let idsNotInFriendList = idsInOldAddedFriendList.filter(id => !idsInFriendList.includes(id));
     state.friendRemovedInEdit=idsNotInFriendList
     //maintain list for add member form list    
     let idsInOldAddedFriendListForAdd = new Set(state.oldAddedFriendList.map((data:any) => Number(data?.id)));
     let uniqueFriendList = state.FriendList.filter((data:any) => !idsInOldAddedFriendListForAdd.has(Number(data?.id)));
     state.friendAddedInEdit=uniqueFriendList
    },

    removeFromFriendList(state, action: PayloadAction<number>) {
      let updateArray = state.FriendList.filter((data: any) => {
        return data.id != action.payload
      })

      state.FriendList = updateArray
      
     let idsInOldAddedFriendList = state.oldAddedFriendList.map(data => Number(data?.id));
     let idsInFriendList = state.FriendList.map((data:any) => Number(data?.id));
     let idsNotInFriendList = idsInOldAddedFriendList.filter(id => !idsInFriendList.includes(id));
     state.friendRemovedInEdit=idsNotInFriendList
     //maintain list for add member form list    
     let idsInOldAddedFriendListForAdd = new Set(state.oldAddedFriendList.map((data:any) => Number(data?.id)));
     let uniqueFriendList = state.FriendList.filter((data:any) => !idsInOldAddedFriendListForAdd.has(Number(data?.id)));
     state.friendAddedInEdit=uniqueFriendList
    },
    setGroupId(state, action: PayloadAction<object>) {
      state.GroupList.push(action.payload)
    },

    removeFromGroupList(state, action: PayloadAction<number>) {
      let updateArray = state.GroupList.filter((data: any) => {
        return data.id != action.payload
      })

      state.GroupList = updateArray
    },
    setGroupListing(state, action: PayloadAction<any>) {
      const payloadRecord = action.payload
      // Check if the record already exists in the list
      const recordExists = state.GroupListing.some(
        (existingRecord: any) => existingRecord?._id === payloadRecord?._id
      )

      // If the record doesn't exist, push it to the list
      if (!recordExists) {
        state.GroupListing.push(payloadRecord)
      }
    },

    setGroupListingEmpty(state) {
      state.GroupListing = []
    },
    setRoomId(state, action: PayloadAction<string>) {
      state.roomId = action.payload
    },
    setShowEditModal(state, action: PayloadAction<boolean>) {
      state.ShowEditModal = action.payload
    },
    setGroupChatApiCall(state, action: PayloadAction<number>) {
      state.callApiForGroupChat = action.payload
    },
    setDeleteModalShow(state, action: PayloadAction<boolean>) {
      state.ShowDeleteModal = action.payload
    },
    setOpenRoomDetail(state, action: PayloadAction<any>) {
      state.openRoomDetail = []
      state.openRoomDetail.push(action.payload)
    },
    setRemovedUserList(state, action: PayloadAction<any>) {
      const friendIdToRemove = action.payload

      // Create a new array excluding the element with the specified id
      state.oldAddedFriendList = state.oldAddedFriendList.filter(
        (friend) => friend.id !== friendIdToRemove
      )
      state.removedUserList.push(action.payload)
    },
    setOldAddedFriendList(state, action: PayloadAction<any>) {
      const newFriend = action.payload

      // Check if the friend with the same id already exists
      const existingFriend = state.oldAddedFriendList.find((friend) => friend.id === newFriend.id)

      // If the friend doesn't exist, push it into the array
      if (!existingFriend) {
        state.oldAddedFriendList.push(newFriend)
      }
    },
    setEmptyStates(state){
      state.oldAddedFriendList=[];

      state.removedUserList=[]
    },setShowLeaveModal(state,action:PayloadAction<boolean>){
      state.ShowLeaveModal=action.payload

    },
    setGroupLimit(state,action:PayloadAction<number>){
      state.groupLimit=action.payload

    },setIsGroupCreate(state,action:PayloadAction<boolean>){
      state.isGroupCreate=action.payload

    },
    setLimitExceedPopup(state,action:PayloadAction<boolean>){
      state.limitExceedPopup=action.payload

    },
    setAddFriendLimit(state,action:PayloadAction<number>){
      state.FriendAddLimit=action.payload
    },
    setCharacterLimit(state,action:PayloadAction<number>){
      state.characterLimit=action.payload
    },
    setCharacterLimitExceedPopUp(state,action:PayloadAction<boolean>){
      state.characterLimitExceedPopUp=action.payload
    },
    setChatLimitExceed(state,action:PayloadAction<boolean>){
      state.chatLimit = action.payload
    },
    setIsGrowthPlan(state,action:PayloadAction<boolean>){
      state.isGrowthPlan = action.payload
    },
    setLimitExceedTitle(state,action:PayloadAction<string>){
      state.LimitExceedTitle = action.payload
    },
    setEmptyFriendsList(state){
      state.FriendList=[]

    },
    setIsLocked(state,action:PayloadAction<boolean>){

      state.isLocked=action.payload
    }

  },
})

export default GroupChatSlice.reducer

export const {
  setFriendId,
  removeFromFriendList,
  setGroupId,
  removeFromGroupList,
  setGroupListing,
  setGroupListingEmpty,
  setRoomId,
  setShowEditModal,
  setGroupChatApiCall,
  setDeleteModalShow,
  setOpenRoomDetail,
  setRemovedUserList,
  setOldAddedFriendList,
  setEmptyStates,
  setShowLeaveModal,
  setGroupLimit,
  setIsGroupCreate,
  setLimitExceedPopup,
  setAddFriendLimit,
  setCharacterLimit,
  setCharacterLimitExceedPopUp,
  setChatLimitExceed,
  setIsGrowthPlan,
  setLimitExceedTitle,
  setEmptyFriendsList,
  setIsLocked
} = GroupChatSlice.actions

// AdSenseAd.tsx
import React, {useEffect} from 'react'
import './adsStyle.css'
import {Adsense} from '@ctrl/react-adsense';
const BottomHorizontalAd = () => {
/*   useEffect(() => {
    try {
      // Initiates a new AdSense ad request
      if (typeof window.adsbygoogle !== "undefined") {
        (window.adsbygoogle = (window as any).adsbygoogle || []).push({});
      }
      
    } catch (e) {
      console.error('Error loading AdSense ad:', e)
    }
  }, []) */
  return (
   /*  <div  className='adparent' style={{minWidth:'320px',margin:'auto',textAlign:'center'}}>
 
      <ins
        className='adsbygoogle '
        style={{display: 'block'}}
        data-ad-client='ca-pub-6621414707273445'
        data-ad-slot='3766910729'
        data-ad-format='auto'
        data-full-width-responsive='true'
      ></ins>
    </div> */
    <Adsense
      client="ca-pub-6621414707273445"
      slot="3766910729"
      style={{ display: 'block' }}
      format='auto'
      responsive='true'
  />
  )
}

export default BottomHorizontalAd

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  showPaymentModal: boolean
  showAdOnPaymentModal: boolean
  price_id: string
  plan_id: string
  token_id: string
  CallApi: number
  IsSubscriptionActive: boolean
  monthly_price_id: string
  yearly_stripe_Id: string
  month_price:string
  yearly_price:string
  selectedPlan:string
  addToCart:boolean
}
const initialState: IssueInitialState = {
  showPaymentModal: false,
  showAdOnPaymentModal:false,
  price_id: '',
  plan_id: '',
  token_id: '',
  CallApi: 0,
  IsSubscriptionActive: false,
  yearly_stripe_Id: '',
  monthly_price_id: '',
  month_price:"",
  yearly_price:"",
  selectedPlan:"",
  addToCart:false

}
const stripePayment = createSlice({
  name: 'stripePayment',
  initialState,
  reducers: {
    setPaymentModal(state, action: PayloadAction<boolean>) {
      state.showPaymentModal = action.payload
    },
    setAdOnPaymentModal(state, action: PayloadAction<boolean>) {
      state.showAdOnPaymentModal = action.payload
    },
    setPriceIdAndPlanId(
      state,
      action: PayloadAction<{price_id: string; plan_id: string; token_id: string}>
    ) {
      state.price_id = action.payload.price_id
      state.plan_id = action.payload.plan_id
      state.token_id = action.payload.token_id
    },
    setToken(state, action: PayloadAction<string>) {
      state.token_id = action.payload
    },
    setNumber(state, action: PayloadAction<number>) {
      state.CallApi = action.payload
    },
    CheckIsSubscribed(state, action: PayloadAction<boolean>) {
      state.IsSubscriptionActive = action.payload
    },
    setPriceId(state, action: PayloadAction<string>) {
      state.price_id = action.payload
    },
    setMonthlyAndYearPriceId(
      state,
      action: PayloadAction<{monthly_price_id: string; yearly_price_id: string}>
    ) {
      state.monthly_price_id = action.payload.monthly_price_id
      state.yearly_stripe_Id = action.payload.yearly_price_id
    },
    setMonthlyPrice(state, action: PayloadAction<string>){
      state.month_price=action.payload

    },
    setYearlyPrice(state, action: PayloadAction<string>){
      state.yearly_price=action.payload

    },
    setSelectedPlan(state, action: PayloadAction<string>){
      state.selectedPlan=action.payload
    },
    setAddOnCart(state, action: PayloadAction<boolean>){
      state.addToCart=action.payload
    },
    setEmptyAllState(state){

      state.showPaymentModal= false
      state.price_id= ''
      state.plan_id= ''
      state.token_id= ''
      // state.CallApi= 0
      state.IsSubscriptionActive= false
      state.yearly_stripe_Id= ''
      state.monthly_price_id= ''
      state.month_price=""
      state.yearly_price=""
      state.selectedPlan=""
    }

  },
})

export default stripePayment.reducer

export const {
  setPaymentModal,
  setAdOnPaymentModal,
  setPriceIdAndPlanId,
  setToken,
  setNumber,
  CheckIsSubscribed,
  setPriceId,
  setMonthlyAndYearPriceId,
  setSelectedPlan,
  setYearlyPrice,
  setMonthlyPrice,
  setEmptyAllState,
  setAddOnCart

} = stripePayment.actions

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {

  apiStatus: boolean
  apiMessage: string
  isDeleted: boolean
  isCreated: boolean
  CallApi: number
  isUpdated: boolean,
  isAction:boolean,
  redirectPageAfterEventCreation:string
  

}
const initialState: IssueInitialState = {

  apiStatus: false,
  apiMessage: '',
  isDeleted: false,
  isUpdated: false,
  isCreated: false,
  CallApi: 0,
  isAction:false,
  redirectPageAfterEventCreation:""

}
const ResponseHandling = createSlice({
  name: 'ResponseHandling',
  initialState,
  reducers: {
   
    setIsDeleted(state, action: PayloadAction<boolean>) {
      state.isDeleted = action.payload
    },
    setApiResponse(state, action: PayloadAction<{apiStatus: boolean; apiMessage: string}>) {
      state.apiStatus = action.payload.apiStatus
      state.apiMessage = action.payload.apiMessage
    },
    setCallApi(state, action: PayloadAction<number>) {
      state.CallApi = action.payload
    },
    setIsUpdated(state, action: PayloadAction<boolean>) {
      state.isUpdated = action.payload
    },
    setIsCreated(state, action: PayloadAction<boolean>) {
      state.isCreated = action.payload
    },
    setAction(state,action:PayloadAction<boolean>){
        state.isAction=action.payload

    },setRedirectPageAfterEventCreation(state,action:PayloadAction<string>){
       state.redirectPageAfterEventCreation=action.payload
    }

  },
})

export default ResponseHandling.reducer

export const {

  setIsDeleted,
  setApiResponse,
  setCallApi,
  setIsUpdated,
  setIsCreated,
  setAction,
  setRedirectPageAfterEventCreation

} = ResponseHandling.actions

import {configureStore} from '@reduxjs/toolkit'
import EventScheduleSlice from './slices/EventScheduleSlice'
import EventHistoryDropDownSlice from './slices/EventHistoryDropDownSlice'
import FriendDropDownSlice from './slices/FriendDropDownSlice'
import AddToScheduleSlice from './slices/AddToScheduleSlice'
import RightToolBarSlice from './slices/RightToolBarSlice'
import ScheduleViewDropDown from './slices/ScheduleViewDropDown'
import GroupDropDownSlice from './slices/GroupDropDownSlice'
import HomePageCardDropDown from './slices/HomePageCardDropDown'
import SearchCategoryDropDownSlice from './slices/SearchCategoryDropDownSlice'
import SearchDateDropDownSlice from './slices/SearchDateDropDownSlice'
import UpadNoteDropDownSlice from './slices/UpadNoteDropDownSlice'
import CreateNewPinPointSlice from './slices/CreateNewPinPointSlice'
import PinPointCreatedSlice from './slices/PinPointCreatedSlice'

import CardEventDropDownSlice from './slices/Ushare/ShareScheduleSlices/ShareScheduleEventDropDownSlice'
import ShareScheduleCatgeoryDropDownSlice from './slices/Ushare/ShareScheduleSlices/ShareScheduleCatgeoryDropDownSlice'
import ShareScheduleDateDropDownSlice from './slices/Ushare/ShareScheduleSlices/ShareScheduleDateDropDownSlice'
import CatgeoryDropDownSlice from './slices/Ushare/Friends/CatgeoryDropDownSlice'
import DateDropDownSlice from './slices/Ushare/Friends/DateDropDownSlice'
import ShareScheduleMediaSlice from './slices/Ushare/ShareScheduleSlices/ShareScheduleMediaSlice'

import QrCodeTimeSlice from './slices/Ushare/ShareScheduleSlices/QrCodeTimeSlice'

import CustomQrCodeStateSlice from './slices/Ushare/ShareScheduleSlices/CustomQrCodeStateSlice'

import ScanFriendModalSlice from './slices/Ushare/ScanFriends/ScanFriendModalSlice'

import MapOfUseDropDownSlice from './slices/Settings/Account/MapOfUseDropDownSlice'
import SelectSchoolDropDownSlice from './slices/Settings/Account/SelectSchoolDropDownSlice'

import InviteUserSlice from './slices/Schedule/InviteUserSlice'

import SelectFriend from './slices/Ushare/Friends/SelectFriend'
import SectionSlice from './slices/SectionSlice'
import AddTripSlice from './slices/AddTripSlice'
import PinPointSelectedSlice from './slices/PinPointSelectedSlice'
import UPadNotesIdSlice from './slices/UPadNotesIdSlice'
import ShowModalSlice from './slices/ShowModalSlice'

import SearchCategoryDropDownSliceForHome from './slices/SearchCategoryDropDownSliceForHome'
import SearchDateDropDownSliceForHome from './slices/SearchDateDropDownSliceForHome'
import SearchEvent from './slices/Home/SearchEvent'
import CallApiSlice from './slices/CallApiSlice'

import CreateEventEveryWeekSlice from './slices/CreateEventEveryWeekSlice'
import CreateEventDataSlice from './slices/CreateEventDataSlice'
import EventDetailSlice from './slices/EventDetailSlice'
import MessageModalSlice from './slices/MessageModalSlice'
import EditEventDataSlice from './slices/EditEventDataSlice'
import SelectEventIdSlice from './slices/SelectEventIdSlice'
import ApiCallIntailtor from './slices/ApiCallIntailtor'
import UMapSlice from './slices/UMapSlice'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import ShareYourScheduleDataslice from './slices/Ushare/ShareScheduleSlices/ShareYourScheduleDataslice'
import ResponseHandlingSlice from './slices/ResponseHandlingSlice'

import StripePaymentSlice from './slices/StripePaymentSlice'
import UserSubscriptionDetail from './slices/UserSubscriptionDetail'

import SubscriptionAlertSlice from './slices/SubscriptionAlertSlice'
import UserManagementSlice from './slices/User_management/UserManagementSlice'
import LoadingSpinerSlice from './slices/LoadingSpinerSlice'
import GroupChatSlice from './slices/Group_chat/GroupChatSlice'
import UTripSlice from "./slices/UTripSlice";

import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist"
import { combineReducers } from '@reduxjs/toolkit'
import HandleVideoAdSlice from './slices/HandleVideoAdSlice'
import AuthSlice from './slices/Settings/Account/AuthSlice'
import AiChatSlice from './slices/AiChatSlice'

const persistConfig = {
  key:'root',
  version:1,
  storage
}

const reducer = combineReducers({
  AddToScheduleData: AddToScheduleSlice,
  AddTrip: AddTripSlice,
  ApiCallIntailtor: ApiCallIntailtor,
  CallApi: CallApiSlice,
  CardEventDropDown: CardEventDropDownSlice,
  CreateEventDataSlice: CreateEventDataSlice,
  CreateEventEveryWeekSlice: CreateEventEveryWeekSlice,
  CreateNewPinPoint: CreateNewPinPointSlice,
  CustomQrSlice: CustomQrCodeStateSlice,
  EditEventDataSlice: EditEventDataSlice,
  EventDetailSlice: EventDetailSlice,
  EventHistory: EventHistoryDropDownSlice,
  EventSchedule: EventScheduleSlice,
  
  FriendCategoryDropDown: CatgeoryDropDownSlice,
  FriendDateDropDown: DateDropDownSlice,
  FriendDropDown: FriendDropDownSlice,
  GroupDropDown: GroupDropDownSlice,
  HomePageCardDropDown: HomePageCardDropDown,
  InviteUser: InviteUserSlice,
  MapOfUseDropDown: MapOfUseDropDownSlice,
  MessageModalSlice: MessageModalSlice,
  PinPointCreated: PinPointCreatedSlice,
  PinPointSelected: PinPointSelectedSlice,
  QrCodeTime: QrCodeTimeSlice,
  ResponseHandlingSlice: ResponseHandlingSlice,
  RightToolBar: RightToolBarSlice,
  ScanFriendModalSlice: ScanFriendModalSlice,
  ScheduleView: ScheduleViewDropDown,
  SearchCategoryDropDown: SearchCategoryDropDownSlice,
  SearchCategoryDropDownSliceForHome: SearchCategoryDropDownSliceForHome,
  SearchDateDropDown: SearchDateDropDownSlice,
  SearchDateDropDownSliceForHome: SearchDateDropDownSliceForHome,
  SearchEvent: SearchEvent,
  // Category and date slice for home seacrh
  Section: SectionSlice,
  SelectEventIdSlice: SelectEventIdSlice,
  SelectFriend: SelectFriend,
  SelectSchoolDropDown: SearchCategoryDropDownSlice,
  SelectSchoolDropDown2: SelectSchoolDropDownSlice,
  ShareScheduleCatgeoryDropDown: ShareScheduleCatgeoryDropDownSlice,
  ShareScheduleDateDropDown: ShareScheduleDateDropDownSlice,
  ShareScheduleMedia: ShareScheduleMediaSlice,
  ShareYourScheduleDataslice: ShareYourScheduleDataslice,
  ShowModal: ShowModalSlice,
  UMap: UMapSlice,
  UTrip: UTripSlice,
  UPadNotesId: UPadNotesIdSlice,
  UpadNoteDropDown: UpadNoteDropDownSlice,
  StripePaymentSlice:StripePaymentSlice,
  UserSubscriptionDetail:UserSubscriptionDetail,
  SubscriptionAlertSlice:SubscriptionAlertSlice,

  UserManagementSlice:UserManagementSlice,
  LoadingSpinerSlice:LoadingSpinerSlice,
  GroupChatSlice:GroupChatSlice,
  HandleVideoAdSlice:HandleVideoAdSlice,
  AuthSlice:AuthSlice,
  AiChatSlice:AiChatSlice
})

const persistedReducer = persistReducer(persistConfig,reducer)

const store = configureStore({
  reducer: persistedReducer,
  // {
    // AddToScheduleData: AddToScheduleSlice,
    // AddTrip: AddTripSlice,
    // ApiCallIntailtor: ApiCallIntailtor,
    // CallApi: CallApiSlice,
    // CardEventDropDown: CardEventDropDownSlice,
    // CreateEventDataSlice: CreateEventDataSlice,
    // CreateEventEveryWeekSlice: CreateEventEveryWeekSlice,
    // CreateNewPinPoint: CreateNewPinPointSlice,
    // CustomQrSlice: CustomQrCodeStateSlice,
    // EditEventDataSlice: EditEventDataSlice,
    // EventDetailSlice: EventDetailSlice,
    // EventHistory: EventHistoryDropDownSlice,
    // EventSchedule: EventScheduleSlice,
    // FriendCategoryDropDown: CatgeoryDropDownSlice,
    // FriendDateDropDown: DateDropDownSlice,
    // FriendDropDown: FriendDropDownSlice,
    // GroupDropDown: GroupDropDownSlice,
    // HomePageCardDropDown: HomePageCardDropDown,
    // InviteUser: InviteUserSlice,
    // MapOfUseDropDown: MapOfUseDropDownSlice,
    // MessageModalSlice: MessageModalSlice,
    // PinPointCreated: PinPointCreatedSlice,
    // PinPointSelected: PinPointSelectedSlice,
    // QrCodeTime: QrCodeTimeSlice,
    // ResponseHandlingSlice: ResponseHandlingSlice,
    // RightToolBar: RightToolBarSlice,
    // ScanFriendModalSlice: ScanFriendModalSlice,
    // ScheduleView: ScheduleViewDropDown,
    // SearchCategoryDropDown: SearchCategoryDropDownSlice,
    // SearchCategoryDropDownSliceForHome: SearchCategoryDropDownSliceForHome,
    // SearchDateDropDown: SearchDateDropDownSlice,
    // SearchDateDropDownSliceForHome: SearchDateDropDownSliceForHome,
    // SearchEvent: SearchEvent,
    // // Category and date slice for home seacrh
    // Section: SectionSlice,
    // SelectEventIdSlice: SelectEventIdSlice,
    // SelectFriend: SelectFriend,
    // SelectSchoolDropDown: SearchCategoryDropDownSlice,
    // SelectSchoolDropDown2: SelectSchoolDropDownSlice,
    // ShareScheduleCatgeoryDropDown: ShareScheduleCatgeoryDropDownSlice,
    // ShareScheduleDateDropDown: ShareScheduleDateDropDownSlice,
    // ShareScheduleMedia: ShareScheduleMediaSlice,
    // ShareYourScheduleDataslice: ShareYourScheduleDataslice,
    // ShowModal: ShowModalSlice,
    // UMap: UMapSlice,
    // UTrip: UTripSlice,
    // UPadNotesId: UPadNotesIdSlice,
    // UpadNoteDropDown: UpadNoteDropDownSlice,
    // StripePaymentSlice:StripePaymentSlice,
    // UserSubscriptionDetail:UserSubscriptionDetail,
    // SubscriptionAlertSlice:SubscriptionAlertSlice,

    // UserManagementSlice:UserManagementSlice,
    // LoadingSpinerSlice:LoadingSpinerSlice,
    // GroupChatSlice:GroupChatSlice
  // },
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

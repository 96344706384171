import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: boolean
    pin_id:number
    callPinPoinApi:number
}
const initialState: IssueInitialState = {
    type:false,
    pin_id:0,
    callPinPoinApi:0
}
const PinPointSelectedSlice=createSlice({
    name:"PinPointSelected",
    initialState,
    reducers:{
        setPinPointSelected(state, action: PayloadAction<boolean>){
             state.type=action.payload
        },
        setPinId(state, action: PayloadAction<number>){
            state.pin_id=action.payload

        },
        setCallPinpointApiCall(state){
            let random=Math.floor(Math.random() * (9999 - 1 + 1)) + 1;
            state.callPinPoinApi=random

        }
        
    }


});


export default PinPointSelectedSlice.reducer

export const {setPinPointSelected,setPinId,setCallPinpointApiCall}=PinPointSelectedSlice.actions;
import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: boolean
}
const initialState: IssueInitialState = {
    type:false
}
const PinPointCreatedSlice=createSlice({
    name:"PinPointCreated",
    initialState,
    reducers:{
        setPinPointCreated(state, action: PayloadAction<boolean>){
             state.type=action.payload
          
             /* 
            return ;  */
        },
        
    }


});


export default PinPointCreatedSlice.reducer

export const {setPinPointCreated}=PinPointCreatedSlice.actions;
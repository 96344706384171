import { createSlice ,PayloadAction } from "@reduxjs/toolkit";

export interface IssueInitialState {
    type: string
}
const initialState: IssueInitialState = {
    type:""
}
const DateDropDownSlice=createSlice({
    name:"FriendDateDropDown",
    initialState,
    reducers:{
        setDateItem(state, action: PayloadAction<string>){
             state.type=action.payload
            
        },
        getDateItem(state,action){
      
        }
        
    }


});


export default DateDropDownSlice.reducer

export const {setDateItem,getDateItem}=DateDropDownSlice.actions;
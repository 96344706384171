import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  showModal: boolean,
  message:string,

}
const initialState: IssueInitialState = {
    showModal: false,
    message:"",
}
const SubscriptionAlertSlice = createSlice({
  name: 'SubscriptionAlertSlice',
  initialState,
  reducers: {
    

    setSubscriptionAlert(state,action:PayloadAction<{showModal:boolean,message:string}>){

        state.showModal=action.payload.showModal
        state.message=action.payload.message
    }
  },
})

export default SubscriptionAlertSlice.reducer

export const {setSubscriptionAlert} = SubscriptionAlertSlice.actions

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, ChangeEvent} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
// import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {auth, provider, requestPermission} from './LoginWithGoogle/config'
import {
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import 'firebase/auth'
import firebase from 'firebase/app'
import {useDispatch, useSelector} from 'react-redux'
import {
  setOperatingSystem,
  setShowAsideDropdown,
  setSubAdminsLength,
  setSubName,
  setSubscripitionPlanType,
  setSubscriptionDetail,
  setSubscriptionPlanRemainingFreeDays,
  setUshareSubscriptionId,
} from '../../../../store/slices/UserSubscriptionDetail'
import {setIsAddOnActive} from '../../../../store/slices/User_management/UserManagementSlice'
import { MessageModal } from '../../event/components/MessageModal'
import { RootState } from '../../../../store'
import { setIsLogin, setMessageModalState } from '../../../../store/slices/MessageModalSlice'
import { setPopForAccountActivation, setPopForResendEmail } from '../../../../store/slices/Settings/Account/AuthSlice'
import { setApiResponse } from '../../../../store/slices/ResponseHandlingSlice'
import { setCurrentLocationLatLng } from '../../../../store/slices/EventDetailSlice'
import { setPassword } from '../../../../store/slices/Settings/Account/SelectSchoolDropDownSlice'
import UpdatePassword from './ChangePassword'

const signInSchema = Yup.object({
  email: Yup.string().required('Email/UserName is required'),
  password: Yup.string()
    .min(8)
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%*#?&]{7,}$/,
      'Password must contain at least one uppercase letter, one number, and one special character'
    ),
})
const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const dispatch = useDispatch()
  const [InvalidCredentails, setInvalidCredentails] = useState('')
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showLoginMessage,setShowLoginMessage] = useState('')
  const [loginStatus,setLoginStatus] = useState(false)
  const [togglePasswordIcon,setTogglePasswordIcon] = useState(false)
  const [showChangePasswordModal,setShowChangePasswordModal]=useState(false)
  const [oldUserEmail,setOldUserEmail]=useState("")
  const navigate = useNavigate()
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const currentMonth =(currentDate.getMonth() + 1).toString().padStart(2, '0');
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signInSchema,
    onSubmit: (values) => {
      UserLogin()
    },
  })

  const isShow = useSelector((state:RootState)=>{
    return state.MessageModalSlice.isShow
  })
  const authSliceData=useSelector((state:RootState)=>{

    return state.AuthSlice
  })
  const responseHandling = useSelector((state: RootState) => {
    return state.ResponseHandlingSlice
  })

  const authToken = localStorage.getItem('token')
  const nauthToken = authToken?.replaceAll(/"/g, '')

 /*  let fcm_token= localStorage.getItem('fcm_token')?.replaceAll(/"/g, '') */

  const OperatingSystem = localStorage.getItem('operatingSystem')

  function isSafari() {
    return false
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }
  useEffect(() => {
    const authToken = localStorage.getItem('token')
    const nauthToken = authToken?.replaceAll(/"/g, '')
    if (nauthToken) {
      /* localStorage.clear() */
      
      navigate('/schedule/personal_schedule')
    }
  }, [])
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
        
          dispatch(setCurrentLocationLatLng({lat: latitude, lng: longitude}))
        },
        (error) => {
          console.error('Error getting geolocation:', error)
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('User denied the request for Geolocation.')
              break
            case error.POSITION_UNAVAILABLE:
              console.error('Location information is unavailable.')
              break
            case error.TIMEOUT:
              console.error('The request to get user location timed out.')
          }
        },
        {
          enableHighAccuracy: true, // Request high accuracy location
          timeout: 10000, // Timeout after 10 seconds
          maximumAge: 0, // No cache
        }
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  },[])

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()

    if (userAgent.includes('windows')) {
      localStorage.setItem('operatingSystem', 'google')
    } else if (userAgent.includes('macintosh')) {
      localStorage.setItem('operatingSystem', 'apple')
    } else {
      localStorage.setItem('operatingSystem', 'google')
    }
  }, [])

  useEffect(() => {
    if (!isShow) {
      setLoginStatus(true)
    }
  }, [isShow])

  const RomeveServerError = () => {
    setInvalidCredentails('')
  }

  const getUserData = async () => {
    const authToken = localStorage.getItem('token')
    const nauthToken = authToken?.replaceAll(/"/g, '')

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/profileData`, {
        headers: {
          Authorization: `Bearer ${nauthToken}`,
        },
      })


      localStorage.setItem('profile-img', response?.data?.data?.file_url)
    } catch (error) {
    }
  }

  const loginWithGoogle = () => {
    signInWithPopup(auth, provider).then((data: any) => {

      CallApiUserLoginWithGoogle(data.user.email, data.user.accessToken)
    })
  }

  const loginWithApple = async () => {
    try {
      const appleProvider = new OAuthProvider('apple.com')
      // Customize the OAuth provider URL for Sign In with Apple
      appleProvider.setCustomParameters({
        prompt: 'select_account', // This is important for re-authentication
      })

      const result: any = await signInWithPopup(auth, appleProvider)

      // Get user information from the result.user object
      const {displayName, email, uid} = result.user

      // Access the access token from the result.credential.accessToken
      //const accessToken = result.credential.accessToken;

      CallApiUserLoginWithApple(email, uid)
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const CallApiUserLoginWithApple = async (email: String, provideID: string) => {
    /* event.preventDefault() */
    setLoading(true)
  
    /* setTimeout(()=>{
  navigate("/schedule/peronal_schedule");

  },500); */

    //alert("sign in  before api call")
    if (!isSafari()) {
     let getFcm=await requestPermission()
    } else {
      console.warn('Functions not executed on Safari.')
    }
    let fcm_token= localStorage.getItem('fcm_token')?.replaceAll(/"/g, '')

    let data = {
      email: email.toString(),
      password: formik.values.password,
      device_id: fcm_token ?? "",
      source: 'web',
      continue_to_apple: '1',
      provider_id: provideID,
      provider: 'apple',
    }

    let formData = new FormData()

    formData.append('email/username', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data.device_id)
    formData.append('source', data.source)
    formData.append('continue_to_apple', data.continue_to_apple)
    formData.append('provider_id', data.provider_id)
    formData.append('provider', data.provider)

    /* formData.append('source', data.source) */


    /*  for (let key in data) {
    formData.append(key, data[key] as string); // Use type assertion here
  } */

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/user/login/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      if (response.data.status === true) {
        // If response.status is true, navigate to the next page

        localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(response.data.email))
        localStorage.setItem('token', JSON.stringify(response.data.access_token))
        localStorage.setItem('name', JSON.stringify(response.data.name))
       
        getUserData()

        localStorage.setItem('walkthrough_login', response.data.walkthrough_login)

        //alert("sign in  after api call")
        /* EventList() */
        let result = await ActiveSubscriptionApi()
      /*   if (!isSafari()) {
          requestPermission()
        } else {
          console.warn('Functions not executed on Safari.')
        } */

       // dispatch(setIsLogin(true))
        navigate('/schedule/personal_schedule')
      } else {
        // Handle the case where response.status is false (Invalid Credentials)
        // You can display an error message or take appropriate action here
        console.error('Invalid Credentials')
      }
      if (response?.data?.status === false) {
        setInvalidCredentails(response?.data.message)
      }
    } catch (error: any) {
      if (error.response) {
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data

        //alert("sign in  after api call"+errorResponse)
        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
      setInvalidCredentails('Invalid credential')
    }

    setLoading(false)
  }

  const CallApiUserLoginWithGoogle = async (email: String, provideID: string) => {
    /* event.preventDefault() */
    setLoading(true)
    /* setTimeout(()=>{
  navigate("/schedule/peronal_schedule");

  },500); */
    //alert("sign in  before api call")
    if (!isSafari()) {
      let getFcm=await requestPermission()
     } else {
       console.warn('Functions not executed on Safari.')
     }
     let fcm_token= localStorage.getItem('fcm_token')?.replaceAll(/"/g, '')

    let data = {
      email: email.toString(),
      password: formik.values.password,
      device_id: fcm_token ?? "",
      source: 'web',
      continue_to_google: '1',
      provider_id: provideID,
      provider: 'google',
    }

    let formData = new FormData()

    formData.append('email/username', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data?.device_id)
    formData.append('source', 'web')
    formData.append('continue_to_google', '1')
    formData.append('provider_id', data.provider_id)
    formData.append('provider', data.provider)

    /* formData.append('source', data.source) */


    /*  for (let key in data) {
    formData.append(key, data[key] as string); // Use type assertion here
  } */

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/user/login/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      if (response.data.status === true) {
        // If response.status is true, navigate to the next page

        localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(response.data.email))
        localStorage.setItem('token', JSON.stringify(response.data.access_token))
        localStorage.setItem('name', JSON.stringify(response.data.name))
        // localStorage.setItem('showAsideDropdown', 'no')
        dispatch(setShowAsideDropdown(false))
        getUserData()

        //alert("sign in  after api call")

  /*       if (!isSafari()) {
          requestPermission()
        } else {
          console.warn('Functions not executed on Safari.')
        } */

        /* EventList() */

        let result = await ActiveSubscriptionApi()
        if (response.data.walkthrough_login === null || response.data.walkthrough_login === '') {
          localStorage.setItem('walkthrough_login', response.data.walkthrough_login)
          //dispatch(setIsLogin(true))

          navigate('/walkthrough')
        } else {
          //dispatch(setIsLogin(true))

          navigate('/schedule/personal_schedule')
        }
      } else {
        // Handle the case where response.status is false (Invalid Credentials)
        // You can display an error message or take appropriate action here
        console.error('Invalid Credentials')
      }
      if (response?.data?.status === false) {
        setInvalidCredentails(response?.data.message)
      }
    } catch (error: any) {
      if (error.response) {
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data
        //alert("sign in  after api call"+errorResponse)
        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
      setInvalidCredentails('Invalid credential')
    }

    setLoading(false)
  }

  const UserLogin = async () => {
  
    /* event.preventDefault() */
    setLoading(true)
    /* setTimeout(()=>{
    navigate("/schedule/peronal_schedule");

    },500); */

    //alert("sign in  before api call")
    if (!isSafari()) {
      let getFcm=await requestPermission()
     } else {
       console.warn('Functions not executed on Safari.')
     }
     let fcm_token= localStorage.getItem('fcm_token')?.replaceAll(/"/g, '')

    let data = {
      email: formik.values.email,
      password: formik.values.password,
      device_id: fcm_token ?? "",
      source: 'web',
    }

    let formData = new FormData()

    formData.append('email/username', data.email)
    formData.append('password', data.password)
    formData.append('device_id', data.device_id)
    formData.append('source', 'web')
    /* formData.append('source', data.source) */


    /*  for (let key in data) {
      formData.append(key, data[key] as string); // Use type assertion here
    } */

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/user/login/${userTimeZone.replaceAll("/","_")}`,
        formData
      )

      setLoginStatus(response.data.status)
      setShowLoginMessage(response.data.message)

      if (response.data.status === true) {
        // If response.status is true, navigate to the next page

        if(response.data?.old_id){
          setLoading(false)
          setOldUserEmail(response.data.email)
         setShowChangePasswordModal(true)
          return
        }
        localStorage.setItem('user_id', JSON.stringify(response.data.user_id))
        localStorage.setItem('email', JSON.stringify(response.data.email))
        localStorage.setItem('token', JSON.stringify(response.data.access_token))
        localStorage.setItem('name', JSON.stringify(response.data.name))
        //localStorage.setItem('password', JSON.stringify(data.password))
        dispatch(setPassword(data.password))
       
       
        let resultSub=await ActiveSubscriptionApi()
        let resultUser=await getUserData()
        //alert("sign in  after api call")
/* 
        if (!isSafari()) {
          requestPermission()
        } else {
          console.warn('Functions not executed on Safari.')
        } */
        if (response.data.walkthrough_login === null || response.data.walkthrough_login === '') {
          localStorage.setItem('walkthrough_login', response.data.walkthrough_login)
         // dispatch(setIsLogin(true))

          navigate('/walkthrough')
        } else {
         // dispatch(setIsLogin(true))

         if( response?.data?.message?.includes("Your account has been reactivated")){
          dispatch(setPopForAccountActivation(true))
          
          dispatch(setMessageModalState({isShow: true, status: true}))
         }else{
          navigate('/schedule/personal_schedule')
         }

        
        }

        /* EventList() */
      } else {
        // Handle the case where response.status is false (Invalid Credentials)
        // You can display an error message or take appropriate action here
   /*      dispatch(setMessageModalState({isShow:true,status:true})) */
        console.error('Invalid Credentials')
      }

      if (response?.data?.status == false) {
        setInvalidCredentails(response?.data.message)
      }
    } catch (error: any) {
      if (error.response) {
        // The request was made, and the server responded with an error status
        const errorResponse = error.response.data
        //alert("sign in  after api call"+errorResponse)

        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          // This indicates a server-side validation error
          console.error('Validation errors:', errorResponse.errors)
        }

        console.error('Status code:', error.response.status)
      } else if (error.request) {
        // The request was made, but no response was received (e.g., network error)
        console.error('No response received:', error.request)
      } else {
        // Something happened in setting up the request (e.g., request config error)
        console.error('Error setting up the request:', error.message)
      }
      setInvalidCredentails('Invalid credential')
    }

    setLoading(false)
  }


  const resendVerificationEmail = async () => {
  

    setLoading(true)


    let data = {
      email: formik.values.email,
    
    }

    let formData = new FormData()

    formData.append('email/username', data.email)


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/resendVerificationEmail`,
        formData
      )

      setLoginStatus(response.data.status)
      setShowLoginMessage(response.data.message)

     
      dispatch(setPopForResendEmail(true))
      dispatch(setMessageModalState({isShow: true, status: true}))
      dispatch(setApiResponse({apiStatus: response.data.status, apiMessage: response.data.message}))
     
    } catch (error: any) {
      dispatch(setPopForResendEmail(true))
      dispatch(setMessageModalState({isShow: true, status: true}))
      dispatch(setApiResponse({apiStatus: error.response.data.status, apiMessage: error.response.data.message}))
    }

    setLoading(false)
  }
  const ActiveSubscriptionApi = async () => {
    try {
      const authToken = localStorage.getItem('token')
      const nauthToken = authToken?.replaceAll(/"/g, '')

      if (!authToken) {
        console.error('Authentication token is missing.')
        return
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/getCurrentSubscription/${userTimeZone.replaceAll("/","_").toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
      // dispatch(setSubscripitionPlanType())
      // if (!response.data.message) {
  
      if(response?.data?.trial_days_remaining > 0 && (response.data?.featuresAllowed[0]?.subscription_plan_id == 1 || response.data?.featuresAllowed[0]?.subscription_plan_id == 5)){
        dispatch(setSubName("PLUS"))
      }else if(response?.data?.trial_days_remaining === 0 && (response.data?.featuresAllowed[0]?.subscription_plan_id == 1 || response.data?.featuresAllowed[0]?.subscription_plan_id == 5)){
        dispatch(setSubName("Free"))
      }else if(response.data?.featuresAllowed[0]?.subscription_plan_id == 2 || response.data?.featuresAllowed[0]?.subscription_plan_id == 6){
        dispatch(setSubName("PLUS"))
      }else if(response.data?.featuresAllowed[0]?.subscription_plan_id == 3 || response.data?.featuresAllowed[0]?.subscription_plan_id == 7){
        dispatch(setSubName("PREMIUM"))
      }else if(response.data?.featuresAllowed[0]?.subscription_plan_id == 4 || response.data?.featuresAllowed[0]?.subscription_plan_id == 8){
        dispatch(setSubName("GROWTH"))
      }
      if (
        response.data.subscriptionPlan.plan_name !== null &&
        response.data.subscriptionPlan.plan_provider_name == 'Android' || response.data.subscriptionPlan.plan_provider_name == 'Apple'
      ) {
        const plan_name = response.data.subscriptionPlan.plan_name.split(' ')[1]
        const plan_provider_name = response.data.subscriptionPlan.plan_provider_name
        const startDate_of_SubscriptionPlan = response.data.subscriptionPlan.start_date
        const subscriptionPlanType =
          response.data.subscriptionPlan.plan_name.split(' ')[1] !== 'Free' ? 'paid' : 'free'
        const subscriptionPlanName = response.data.subscriptionPlan.plan_name
        

        //console.log("subscriptionPlanName",subscriptionPlanName)
        
        //console.log("subscriptionPlanName",subscriptionPlanType)
        localStorage.setItem('subscriptionType', subscriptionPlanType)

        dispatch(setUshareSubscriptionId(response.data?.featuresAllowed[0]?.subscription_plan_id))
       
        //dispatch(setSubscriptionPlanRemainingFreeDays(startDate_of_SubscriptionPlan))
        dispatch(setSubscriptionPlanRemainingFreeDays(response.data.trial_days_remaining))
        dispatch(
          setSubscripitionPlanType({
            subscriptionType: subscriptionPlanType,
            subscriptionPlanName: subscriptionPlanName,
          })
        )
        response?.data?.featuresAllowed.forEach((value:any)=>{
          if (value?.feature?.name.includes('sub_admin')) {
            const getSubAdmins = value?.feature?.name?.split('_')
            const no_subAdmins = getSubAdmins[0];
            if(response?.data?.trial_days_remaining > 0 && (response.data?.featuresAllowed[0]?.subscription_plan_id == 1 || response.data?.featuresAllowed[0]?.subscription_plan_id  == 5)){
              
              dispatch(setSubAdminsLength(parseInt("5"))) 
            }else{
              
              dispatch(setSubAdminsLength(parseInt(no_subAdmins))) 
            }
            
          }
        })
        getUsageCostPlans(plan_name)
        getAiCostPlans(plan_name)
      } else {
        const startDate_of_SubscriptionPlan = response.data.subscriptionPlan.start_date
        const subscriptionPlanType = response.data.subscriptionPlan.subscription_plan.type
        const subscriptionPlanName = response.data.subscriptionPlan.subscription_plan.name

        localStorage.setItem('subscriptionType', subscriptionPlanType)

        //dispatch(setSubscriptionPlanRemainingFreeDays(startDate_of_SubscriptionPlan))
        dispatch(setSubscriptionPlanRemainingFreeDays(response.data.trial_days_remaining))
        dispatch(
          setSubscripitionPlanType({
            subscriptionType: subscriptionPlanType,
            subscriptionPlanName: subscriptionPlanName,
          })
        )
        dispatch(setUshareSubscriptionId(response.data?.featuresAllowed[0]?.subscription_plan_id))
        response?.data?.featuresAllowed.forEach((value:any)=>{
          if (value?.feature?.name.includes('sub_admin')) {
            const getSubAdmins = value?.feature?.name?.split('_')
            const no_subAdmins = getSubAdmins[0]
            if(response?.data?.trial_days_remaining > 0 && (response.data?.featuresAllowed[0]?.subscription_plan_id == 1 || response.data?.featuresAllowed[0]?.subscription_plan_id  == 5)){
              dispatch(setSubAdminsLength(parseInt("5"))) 
            }else{
              dispatch(setSubAdminsLength(parseInt(no_subAdmins))) 
            }
          }
        })
      }

      // }

      dispatch(setSubscriptionDetail(response.data))
    } catch (error: any) {
    }
  }

  const getUsageCostPlans = async (planName: any) => {
    
    if (!nauthToken) {
      console.error('Authentication token is missing.')
      return
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/getUsageCostPlans`,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )

      addMapUsageCostAPi(response.data.usageCostPlans, planName)
    } catch (error) {
    }
  }

  const addMapUsageCostAPi = async (usageCostPlans: any, planName: any) => {
    if (!nauthToken) {
      console.error('Authentication token is missing.')
      return
    }
    const {free_user, paid_user_1, paid_user_2} = usageCostPlans
    let planAmount = ''

    if (OperatingSystem === 'google') {
      switch (planName) {
        case 'Free':
          planAmount = free_user
          break
        case 'Plus':
          planAmount = paid_user_1
          break
        case 'Premium':
          planAmount = paid_user_2
          break
        default:
          break
      }
    }
    const formData = new FormData()
    formData.append('amount', planAmount)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/addMapUsageCost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
    } catch (error) {
    }
  }

  const getAiCostPlans = async (planName: any) => {
    if (!nauthToken) {
      console.error('Authentication token is missing.')
      return
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/getAiCostPlans`, {
        headers: {
          Authorization: `Bearer ${nauthToken}`,
        },
      })

      addAiUsageCost(response.data.aiUsageCostPlans, planName)
    } catch (error) {
    }
  }

  const addAiUsageCost = async (aiUsageCostPlans: any, planName: any) => {
    if (!nauthToken) {
      console.error('Authentication token is missing.')
      return
    }
    const {free_user, paid_user_1, paid_user_2} = aiUsageCostPlans
    let allowed_tokens = 0
    let allowed_words = 0

    switch (planName) {
      case 'Free':
        allowed_tokens = free_user.allowed_tokens
        allowed_words = free_user.allowed_words
        break
      case 'Plus':
        allowed_tokens = paid_user_1.allowed_tokens
        allowed_words = paid_user_1.allowed_words
        break
      case 'Premium':
        allowed_tokens = paid_user_2.allowed_tokens
        allowed_words = paid_user_2.allowed_words
        break
      default:
        break
    }

    const formData = new FormData()
    formData.append('tokens', String(allowed_tokens))
    formData.append('words', String(allowed_words))

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/addAiUsageCost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${nauthToken}`,
          },
        }
      )
    } catch (error) {
    }
  }

  const check = () => {
    navigate('/schedule/personal_schedule')
  }

  const inputFIle = {
    fontSize: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
  }

  let styleForFormIcon = {
    maindiv: {
      position: 'relative',
      display: 'flex',
      /* justifyContent: "end",
      alignItems: "end", */
    },
    icon: {
      position: 'absolute',
      top: '12px',
      /* left: "97%", */

      color: '#4b00ff',
      height: '24px',
      width: '24px',
      display: 'flex',
      /* marginRight: "10px", */
      marginRight: '50px',
    },
  }
  const styleForInput = {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    paddingLeft: '50px',
    color: 'rgba(189, 189, 189, 1)',
    /* width: "458px", */
    height: '48px',
    borderRadius: '8px',
  }

  useEffect(() => {
    dispatch(setMessageModalState({isShow: false, status: false}))
    dispatch(setIsAddOnActive(false))
  }, [])

  function togglePassword() {
    setTogglePasswordIcon(!togglePasswordIcon)
    var x = document.getElementById("password")as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='d-flex justify-content-center '>
        <h2
          className='text-white '
          /* style={{marginLeft:"45px"}} */ style={{
            fontSize: '24px',
            fontWeight: '600',
            marginBottom: '48px',
          }}
        >
          Welcome to Ushare Website
        </h2>
      </div>

      {InvalidCredentails ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold' style={{color: 'red'}}>
            {InvalidCredentails === "Please verify your email address."?
            
            <>
            <span>{InvalidCredentails} <b
            style={{
              cursor:"pointer"
            }}
            onClick={()=>{
              resendVerificationEmail()
            }}
            
            > Resend verification email</b></span>
            </>
            :InvalidCredentails
            
            }
          </div>
        </div>
      ) : (
        ''
      )}

      <div className='fv-row ' style={{marginBottom: '16px'}}>
        <div
          className='col-12 form-input'
          style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
        >
          <input
            id='email'
            name='email'
            type='text'
            className='form-control '
            style={{...styleForInput}}
            placeholder='Email/Username'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            autoComplete='off'
            onClick={RomeveServerError}
          />

          <img
            src={toAbsoluteUrl('/media/Home/email.png')}
            alt=''
            style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
          />
        </div>

        {formik.errors.email && formik.touched.email ? (
          <div className='alert-text font-weight-bold  text-danger '>{formik.errors.email}</div>
        ) : (
          ''
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row ' data-kt-password-meter='true' style={{marginBottom: '16px'}}>
        <div
          className='col-12 form-input'
          style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
        >
          <input
            id='password'
            name='password'
            type='password'
            className='form-control'
            style={{...styleForInput}}
            placeholder='Password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onClick={RomeveServerError}
            autoComplete='off'
          />

          <img
            src={toAbsoluteUrl('/media/Home/lock.png')}
            alt=''
            style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
          />
          {togglePasswordIcon ?
            <img
          className='cursor-pointer'
            src={toAbsoluteUrl('/media/Home/openEye.svg')}
            alt=''
            style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
            onClick={togglePassword}
          />
          :
          <img
          className='cursor-pointer'
            src={toAbsoluteUrl('/media/Home/closeEye.svg')}
            alt=''
            style={{position:"absolute",right:'10px',top:'10px',height:"24px",width:"24px"}}
            onClick={togglePassword}
          />
          }
        </div>
        {formik.errors.password && formik.touched.password ? (
          <div className='alert-text font-weight-bold  text-danger'
          
          >{formik.errors.password.charAt(0).toUpperCase() + formik.errors.password.slice(1) }</div>
        ) : (
          ''
        )}
      </div>
      <div className='row '>
        <Link
          to={'/forgetpassword'}
          style={{color: '#FFFFFF', fontSize: '16px', fontWeight: '400'}}
        >
          {' '}
          Forgot password?{' '}
        </Link>
      </div>

      <div className='text-center ' style={{marginTop: '32px'}}>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg  w-100 mb-5'
          style={{
            background: 'rgba(229, 9, 20, 1)',
            height: '48px',
          }}
        >
          {!loading && (
            <span
              className='indicator-label'
              style={{color: 'white', fontSize: '16px', fontWeight: '600'}}
            >
              Sign In
            </span>
          )}

          {loading && (
            <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div
          className=' text-center '
          style={{color: '#828282', marginTop: '32px', fontSize: '16px', fontWeight: '500'}}
        >
          Or Login With{' '}
        </div>

        <div className='row g-3' style={{marginTop: '32px'}}>
          <div
            className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'
            style={{paddingLeft: '5px'}}
          >
            {/* begin::Google link */}
            <a
              href='#'
              className='btn btn-flex btn-outline flex-center text-nowrap'
              style={{
                border: '1px solid rgba(255, 255, 255, 0.24)',
                width: '72%', // Adjust the width as needed
                // Add margin to create space between buttons
                color: 'white',
                fontSize: '16px',
                fontWeight: '600',
              }}
              onClick={loginWithGoogle}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/event/google.png')}
                className='h-24px me-3'
              />
              Google
            </a>
            {/* end::Google link */}
          </div>

          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <a
              href='#'
              className='btn btn-flex btn-outline flex-center text-nowrap'
              style={{
                border: '1px solid rgba(255, 255, 255, 0.24)',
                width: '72%', // Adjust the width as needed
                color: 'white',
                fontSize: '16px',
                fontWeight: '600',
              }}
              onClick={loginWithApple}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/event/Apple.png')}
                className='h-24px me-3'
              />
              Apple
            </a>
          </div>
        </div>

        {/*   <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link> */}
      </div>
      {/* end::Form group */}
      <div
        className=' text-center '
        style={{color: 'white', marginTop: '45px', fontSize: '16px', fontWeight: '400'}}
      >
        Don’t have an account?{' '}
        <Link to='/signup' className='link' style={{color: 'red'}}>
          Create New
        </Link>
      </div>
   
    </form>

     {/* {!loginStatus && (
      <MessageModal
          title='Account'
          imgPath=''
          response={true}
          message={'Please verify your email address.'}
          show={true}
          returnPath='/same_path'
        />
    )} */}

    {
      authSliceData.showPopForResendVerficationEmail === true?
      <MessageModal
      title='Re-verification'
      imgPath=''
      response={true}
      message={responseHandling.apiMessage}
      show={true}
      returnPath='/signin'
    />:""
    }


    {

authSliceData.showPopForAccountReactivation === true?<>
  <MessageModal
          title='Account'
          imgPath=''
          response={true}
          message='Your account has been reactivated'
          show={true}
          returnPath='/login_account_reativation'
        />

</>:""      
    }
    <UpdatePassword show={showChangePasswordModal} onHide={()=>setShowChangePasswordModal(false)} email={oldUserEmail} />
      
    </>
  )
}
